import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Paper, 
  Typography, 
  TextField, 
  Button, 
  Snackbar, 
  Autocomplete,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Box,
  Tabs,
  Tab,
  useMediaQuery,
  useTheme,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';

const styles = {
  paper: { padding: '16px', marginBottom: '16px' },
  textField: { marginBottom: '16px' },
  button: { marginTop: '16px' },
};

const parseDeliveryDate = (dateString) => {
  const dateParts = dateString.match(/\b(January|February|March|April|May|June|July|August|September|October|November|December) (\d{1,2})/g);
  if (!dateParts) return '';

  const currentYear = new Date().getFullYear();
  return dateParts.map(datePart => {
    const date = new Date(`${datePart}, ${currentYear}`);
    return date.toISOString().split('T')[0];
  }).join(' - ');
};

const delay = ms => new Promise(resolve => setTimeout(resolve, ms));

const scrapeProducts = async (keyword, source) => {
  const proxyUrl = `https://api.stayfu.com/proxy?url=${encodeURIComponent(`https://www.${source}.com/s?k=${keyword}`)}`;
  const response = await fetch(proxyUrl, {
    headers: {
      'User-Agent': navigator.userAgent,
    },
    mode: 'no-cors',
  });
  if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  const html = await response.text();
  const doc = new DOMParser().parseFromString(html, 'text/html');

  if (source === 'walmart') {
    return Array.from(doc.querySelectorAll('.search-result-gridview-item'))
      .map(product => ({
        title: product.querySelector('.product-title-link')?.textContent.trim() || '',
        img: product.querySelector('img.product-image-photo')?.src || '',
        price: product.querySelector('.price-main')?.textContent.trim() || '',
        itemId: product.querySelector('.product-title-link')?.href.split('/').pop() || '',
      }))
      .filter(product => product.title && product.img && product.price && product.itemId);
  } else {
    return Array.from(doc.querySelectorAll('.s-result-item'))
      .map(product => ({
        title: product.querySelector('h2 a span')?.textContent || '',
        img: product.querySelector('.s-image')?.src || '',
        price: (product.querySelector('.a-price-whole')?.textContent || '') + 
               (product.querySelector('.a-price-fraction')?.textContent || '00'),
        asin: product.getAttribute('data-asin') || '',
        deliverByDate: parseDeliveryDate(product.querySelector('.a-color-secondary .a-text-bold')?.textContent || '')
      }))
      .filter(product => product.title && product.img && product.price && product.asin);
  }
};

const fetchProductInfo = async (id, source) => {
  const proxyUrl = `https://api.stayfu.com/proxy?url=${encodeURIComponent(`https://www.${source}.com/dp/${id}`)}`;
  const response = await fetch(proxyUrl, {
    headers: {
      'User-Agent': navigator.userAgent,
    },
  });
  if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
  const html = await response.text();
  const doc = new DOMParser().parseFromString(html, 'text/html');

  if (source === 'walmart') {
    const title = doc.querySelector('[itemprop="name"]')?.textContent.trim() || '';
    const img = doc.querySelector('[property="og:image"]')?.content || '';
    const price = doc.querySelector('[itemprop="price"]')?.content || '';
    const shipTime = doc.querySelector('.fulfillment-shipping-text')?.textContent.trim() || '';

    if (!title || !img || !price) throw new Error('Incomplete product information');
    return { title, image: img, price, shipTime };
  } else {
    const title = doc.querySelector('#productTitle')?.textContent.trim() || 
                  doc.querySelector('span#productTitle')?.textContent.trim() || 
                  doc.querySelector('h1')?.textContent.trim() || '';
    const img = doc.querySelector('#imgTagWrapperId img')?.src || 
                doc.querySelector('img#landingImage')?.src || 
                doc.querySelector('div#imgTagWrapper img')?.src || '';
    const priceWhole = doc.querySelector('.a-price-whole')?.textContent.trim() || '';
    const priceFraction = doc.querySelector('.a-price-fraction')?.textContent.trim() || '00';
    const price = priceWhole + priceFraction;
    const shipTime = parseDeliveryDate(doc.querySelector('#mir-layout-DELIVERY_BLOCK span')?.textContent.trim() ||
                                       doc.querySelector('div#delivery-message span.a-text-bold')?.textContent.trim() || '');

    if (!title || !img || !price) throw new Error('Incomplete product information');
    return { title, image: img, price, shipTime };
  }
};

const extractProductId = async (input, source) => {
  console.log(`Extracting ${source} ID from input: ${input}`);
  try {
    const url = new URL(input);
    if (source === 'walmart') {
      const itemIdMatch = url.pathname.match(/\/ip\/(\d+)/);
      if (itemIdMatch) return itemIdMatch[1];
    } else {
      if (url.hostname === 'a.co') {
        const response = await axios.get(`https://api.stayfu.com/api/extractASIN?url=${encodeURIComponent(input)}`);
        return response.data.asin;
      }
      const asinMatch = url.pathname.match(/\/([A-Z0-9]{10})(?:[/?]|$)/i);
      if (asinMatch) return asinMatch[1];
    }
  } catch (e) {
    if (source === 'walmart' && /^\d+$/.test(input)) return input;
    if (source === 'amazon' && /^[A-Z0-9]{10}$/i.test(input)) return input;
  }

  const queryParams = new URLSearchParams(input.split('?')[1]);
  for (const param of queryParams.keys()) {
    if (param.startsWith('ASIN') || param.includes('B0')) {
      const asin = queryParams.get(param);
      if (/^[A-Z0-9]{10}$/i.test(asin)) return asin;
    }
  }

  if (source === 'walmart') {
    const itemIdMatch = input.match(/\/(\d+)(?:[/?]|$)/);
    return itemIdMatch ? itemIdMatch[1] : null;
  } else {
    const fallbackMatch = input.match(/[A-Z0-9]{10}/i);
    return fallbackMatch ? fallbackMatch[0] : null;
  }
};

const truncateTitle = (title, maxLength = 50) => {
  if (title.length <= maxLength) return title;
  return title.substr(0, maxLength) + '...';
};

const MobileProductCard = ({ product, onAdd, source }) => {
  const [quantity, setQuantity] = useState(1);

  return (
    <Card style={{ marginBottom: '16px' }}>
      <CardContent style={{ padding: '8px' }}>
        <Box display="flex" alignItems="center">
          <img src={product.img} alt={product.title} style={{
            width: '50px',
            height: '50px',
            objectFit: 'cover',
            borderRadius: '4px',
            marginRight: '8px',
          }} />
          <Box flexGrow={1}>
            <Tooltip title={product.title}>
              <Typography variant="subtitle1">{truncateTitle(product.title)}</Typography>
            </Tooltip>
            <Typography variant="body2">Price: {product.price}</Typography>
            {source === 'amazon' && (
              <Typography variant="body2">Delivery: {product.deliverByDate}</Typography>
            )}
          </Box>
        </Box>
        <TextField
          type="number"
          label="Quantity"
          value={quantity}
          onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
          InputProps={{ inputProps: { min: 1 } }}
          size="small"
          style={{ marginTop: '8px', width: '100%' }}
        />
      </CardContent>
      <CardActions style={{ justifyContent: 'flex-end', padding: '8px' }}>
        <Button size="small" color="primary" onClick={() => onAdd({ ...product, quantity })}>
          Add to Collection
        </Button>
      </CardActions>
    </Card>
  );
};

const AddEntryView = ({ onAddEntry, properties, collections, updateProperties, updateCollections }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productId, setProductId] = useState('');
  const [bulkIds, setBulkIds] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [failedIds, setFailedIds] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const [source, setSource] = useState('amazon');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const propertyOptions = Array.isArray(properties) ? properties.map(prop => prop.name) : [];

  useEffect(() => {
    if (propertyName) {
      const selectedProperty = properties.find(prop => prop.name === propertyName);
      setFilteredCollections(selectedProperty ? selectedProperty.collections : []);
    } else {
      setFilteredCollections([]);
    }
  }, [propertyName, properties]);

  const createEntry = (id, product) => ({
    id: new Date().getTime(),
    url: `https://www.${source}.com/dp/${id}`,
    propertyName,
    collectionName,
    productImage: product.image,
    title: product.title,
    notes: '',
    ordered: false,
    deliverByDate: product.shipTime,
    budget: '',
    pricePaid: product.price,
    quantity: quantity,
    delivered: false,
  });

  const updatePropertyCollections = () => {
    const updatedProperties = properties.map(property => {
      if (property.name === propertyName) {
        const collectionExists = property.collections.some(coll => coll.name === collectionName);
        if (!collectionExists) {
          return {
            ...property,
            collections: [...property.collections, { name: collectionName, budget: 0 }]
          };
        }
      }
      return property;
    });
    updateProperties(updatedProperties);
  };

  const handleAdd = async () => {
    if (!propertyName || !collectionName) {
      alert('Property Name and Collection Name are required.');
      return;
    }

    const id = await extractProductId(productId, source);
    if (!id) {
      console.error(`Invalid ${source} ID or URL`);
      alert(`Invalid ${source} ID or URL. Please check and try again.`);
      return;
    }

    setLoading(true);
    try {
      const product = await fetchProductInfo(id, source);
      const entry = createEntry(id, product);
      onAddEntry(entry);
      updatePropertyCollections();
      setProductId('');
      setQuantity(1);
      setOpenSnackbar(true);
    } catch (error) {
      console.error('Error fetching product info:', error);
      alert(`Failed to fetch product info for ${source} ID: ${id}`);
    } finally {
      setLoading(false);
    }
  };

  const handleBulkAdd = async () => {
    if (!propertyName || !collectionName) {
      alert('Property Name and Collection Name are required.');
      return;
    }

    const ids = bulkIds.split('\n').map(id => id.trim()).filter(id => id);
    setLoading(true);
    const failed = [];

    for (const id of ids) {
      try {
        const extractedId = await extractProductId(id, source);
        if (!extractedId) {
          failed.push(id);
          continue;
        }
        const product = await fetchProductInfo(extractedId, source);
        const entry = createEntry(extractedId, product);
        onAddEntry(entry);
        updatePropertyCollections();
      } catch (error) {
        console.error(`Error processing ${source} ID:`, id, error);
        failed.push(id);
      }
    }

    setLoading(false);
    setBulkIds('');
    setFailedIds(failed);
    setOpenSnackbar(true);
  };

  const handleSearch = async () => {
    if (!keyword) {
      alert('Please enter a search keyword.');
      return;
    }

    setLoading(true);
    try {
      const scrapedProducts = await scrapeProducts(keyword, source);
      setProducts(scrapedProducts);
    } catch (error) {
      console.error('Error scraping products:', error);
      alert(`Failed to scrape ${source} products. Please try again later.`);
    } finally {
      setLoading(false);
    }
  };

  const handleAddFromSearch = (product) => {
    if (!propertyName || !collectionName) {
      alert('Property Name and Collection Name are required.');
      return;
    }

    const entry = createEntry(source === 'walmart' ? product.itemId : product.asin, product);
    onAddEntry(entry);
    updatePropertyCollections();
    setOpenSnackbar(true);
  };

  return (
    <Container maxWidth="md">
      <Paper style={styles.paper}>
        <Typography variant="h5" gutterBottom>
          Add New Entry
        </Typography>
        <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} centered>
          <Tab label="Single Add" />
          <Tab label="Bulk Add" />
          <Tab label="Search" />
        </Tabs>
        <Box mt={2}>
          <FormControl fullWidth style={styles.textField}>
            <InputLabel>Source</InputLabel>
            <Select value={source} onChange={(e) => setSource(e.target.value)}>
              <MenuItem value="amazon">Amazon</MenuItem>
              <MenuItem value="walmart">Walmart</MenuItem>
            </Select>
          </FormControl>
          <Autocomplete
            options={propertyOptions}
            renderInput={(params) => <TextField {...params} label="Property Name" />}
            value={propertyName}
            onChange={(event, newValue) => setPropertyName(newValue)}
            style={styles.textField}
          />
          <Autocomplete
            options={filteredCollections.map(coll => coll.name)}
            renderInput={(params) => <TextField {...params} label="Collection Name" />}
            value={collectionName}
            onChange={(event, newValue) => setCollectionName(newValue)}
            style={styles.textField}
          />
          {activeTab === 0 && (
            <>
              <TextField
                label={`${source.charAt(0).toUpperCase() + source.slice(1)} ID or URL`}
                value={productId}
                onChange={(e) => setProductId(e.target.value)}
                fullWidth
                style={styles.textField}
              />
              <TextField
                label="Quantity"
                type="number"
                value={quantity}
                onChange={(e) => setQuantity(Math.max(1, parseInt(e.target.value) || 1))}
                fullWidth
                style={styles.textField}
                InputProps={{ inputProps: { min: 1 } }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
                disabled={loading}
                startIcon={<AddIcon />}
                style={styles.button}
              >
                Add Entry
              </Button>
            </>
          )}
          {activeTab === 1 && (
            <>
              <TextField
                label={`${source.charAt(0).toUpperCase() + source.slice(1)} IDs or URLs (one per line)`}
                value={bulkIds}
                onChange={(e) => setBulkIds(e.target.value)}
                fullWidth
                multiline
                rows={4}
                style={styles.textField}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleBulkAdd}
                disabled={loading}
                startIcon={<AddIcon />}
                style={styles.button}
              >
                Bulk Add Entries
              </Button>
            </>
          )}
          {activeTab === 2 && (
            <>
              <TextField
                label="Search Keyword"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                fullWidth
                style={styles.textField}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSearch}
                disabled={loading}
                startIcon={<SearchIcon />}
                style={styles.button}
              >
                Search Products
              </Button>
              {products.length > 0 && (
                <Grid container spacing={2} style={{ marginTop: '16px' }}>
                  {products.map((product, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                      {isMobile ? (
                        <MobileProductCard product={product} onAdd={handleAddFromSearch} source={source} />
                      ) : (
                        <Card>
                          <CardMedia
                            component="img"
                            height="140"
                            image={product.img}
                            alt={product.title}
                          />
                          <CardContent>
                            <Tooltip title={product.title}>
                              <Typography variant="subtitle1" noWrap>
                                {truncateTitle(product.title)}
                              </Typography>
                            </Tooltip>
                            <Typography variant="body2">Price: {product.price}</Typography>
                            {source === 'amazon' && (
                              <Typography variant="body2">Delivery: {product.deliverByDate}</Typography>
                            )}
                          </CardContent>
                          <CardActions>
                            <Button size="small" color="primary" onClick={() => handleAddFromSearch(product)}>
                              Add to Collection
                            </Button>
                          </CardActions>
                        </Card>
                      )}
                    </Grid>
                  ))}
                </Grid>
              )}
            </>
          )}
        </Box>
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message="Entry added successfully!"
      />
      {failedIds.length > 0 && (
        <Paper style={styles.paper}>
          <Typography variant="h6" gutterBottom>
            Failed to add the following IDs:
          </Typography>
          <ul>
            {failedIds.map((id, index) => (
              <li key={index}>{id}</li>
            ))}
          </ul>
        </Paper>
      )}
    </Container>
  );
};

export default AddEntryView;





/* 592 */