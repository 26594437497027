import React from 'react';
import { 
  Box, 
  Typography, 
  Grid, 
  Paper, 
  Button, 
  useTheme, 
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  ListItemIcon
} from '@mui/material';
import { 
  Search as SearchIcon, 
  Add as AddIcon, 
  Build as BuildIcon, 
  Home as HomeIcon, 
  ShoppingCart as ShoppingCartIcon,
  People as PeopleIcon,
  Warning as WarningIcon,
  Inventory as InventoryIcon
} from '@mui/icons-material';
import { Link } from 'react-router-dom';

const DashboardButton = ({ icon, text, to }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Button
      component={Link}
      to={to}
      variant="contained"
      startIcon={icon}
      fullWidth
      sx={{
        height: isMobile ? 100 : 150,
        fontSize: isMobile ? '0.9rem' : '1.1rem',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        '& .MuiButton-startIcon': {
          margin: 0,
          marginBottom: 1,
        },
      }}
    >
      {text}
    </Button>
  );
};

const Dash = ({ urgentMaintenanceItems, urgentRestockItems, urgentDamageItems }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Paper sx={{ p: 2 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={4}>
                <DashboardButton 
                  icon={<SearchIcon />} 
                  text="Search for a Product" 
                  to="/add-entry" 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DashboardButton 
                  icon={<AddIcon />} 
                  text="Add a Property" 
                  to="/property-management" 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DashboardButton 
                  icon={<BuildIcon />} 
                  text="Add Maintenance Item" 
                  to="/maintenance" 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DashboardButton 
                  icon={<HomeIcon />} 
                  text="View Inventory" 
                  to="/inventory" 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DashboardButton 
                  icon={<ShoppingCartIcon />} 
                  text="Purchase Orders" 
                  to="/purchase-orders" 
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <DashboardButton 
                  icon={<PeopleIcon />} 
                  text="Providers" 
                  to="/maintenance" 
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Action Items
            </Typography>
            <List>
              {urgentMaintenanceItems.length > 0 && (
                <ListItem button component={Link} to="/maintenance">
                  <ListItemIcon>
                    <WarningIcon color="error" />
                  </ListItemIcon>
                  <ListItemText 
                    primary={`${urgentMaintenanceItems.length} Urgent Maintenance Items`}
                    secondary="Requires immediate attention"
                  />
                </ListItem>
              )}
              {urgentRestockItems.length > 0 && (
                <ListItem button component={Link} to="/inventory">
                  <ListItemIcon>
                    <InventoryIcon color="warning" />
                  </ListItemIcon>
                  <ListItemText 
                    primary={`${urgentRestockItems.length} Items Need Restocking`}
                    secondary="Low inventory levels or approaching reorder date"
                  />
                </ListItem>
              )}
              {urgentDamageItems.length > 0 && (
                <ListItem button component={Link} to="/damages">
                  <ListItemIcon>
                    <WarningIcon color="error" />
                  </ListItemIcon>
                  <ListItemText 
                    primary={`${urgentDamageItems.length} New Damage Reports`}
                    secondary="Requires immediate attention"
                  />
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dash;
