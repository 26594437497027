import React from 'react';
import axios from 'axios';

const FetchIcalHandler = async (req, res) => {
  const { icalUrl } = req.query;
  try {
    const response = await axios.get(icalUrl, {
      headers: {
        'Accept': 'text/calendar'
      }
    });
    res.set('Content-Type', 'text/calendar');
    res.send(response.data);
  } catch (error) {
    console.error('Error fetching iCal data:', error);
    res.status(500).send('Error fetching iCal data');
  }
};

export default FetchIcalHandler;
