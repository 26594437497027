import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Checkbox, Button, TextField, Toolbar, IconButton, Select, MenuItem,
  FormControl, InputLabel, Chip, Box, Typography, Card, CardContent,
  CardActions, Collapse, List, ListItem, ListItemText, useMediaQuery, useTheme, Tooltip,
  Dialog, DialogTitle, DialogContent, DialogActions, FormControlLabel, Switch
} from '@mui/material';
import {
  Delete as DeleteIcon,
  ShoppingCart as ShoppingCartIcon,
  Save as SaveIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Link as LinkIcon,
} from '@mui/icons-material';

const styles = {
  tableContainer: { width: '100%', overflowX: 'auto' },
  table: { minWidth: 650 },
  toolbar: { justifyContent: 'space-between', flexWrap: 'wrap', gap: 1, p: 1 },
  formControl: { minWidth: 120, m: 1 },
  chip: { margin: 0.5 },
  mobileCard: { mb: 2 },
  mobileCardContent: { p: 1 },
  mobileCardActions: { justifyContent: 'space-between', p: 1 },
  mobileProductImage: { width: 50, height: 50, objectFit: 'cover', borderRadius: 1, mr: 1 },
};

const truncateTitle = (title, maxLength = 40) => {
  return title.length > maxLength ? `${title.substring(0, maxLength - 3)}...` : title;
};

const calculateBudgetComparison = (entries, selectedCollection, budgetData) => {
  if (!selectedCollection) return { totalBudget: 0, totalOrdered: 0, totalSpent: 0, difference: 0 };
  
  const entriesInCollection = entries.filter(entry => entry.collectionName === selectedCollection.name);
  const totalBudget = selectedCollection.budget || 0;
  const totalOrdered = budgetData[selectedCollection.name]?.ordered || 0;
  const totalSpent = budgetData[selectedCollection.name]?.spent || 0;
  const difference = totalBudget - totalOrdered - totalSpent;

  return {
    totalBudget: totalBudget.toFixed(2),
    totalOrdered: totalOrdered.toFixed(2),
    totalSpent: totalSpent.toFixed(2),
    difference: difference.toFixed(2)
  };
};

const getRowStyle = (entry) => {
  if (entry.delivered) return { backgroundColor: '#f0f0f0', color: '#b0b0b0' };
  if (entry.ordered) return { backgroundColor: '#e8f5e9' };
  return { backgroundColor: '#ffebee' };
};

const MobileEntryCard = ({ entry, onSelect, isSelected, onEdit, onRemove }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Card sx={styles.mobileCard}>
      <CardContent sx={styles.mobileCardContent}>
        <Box display="flex" alignItems="center">
          <Tooltip title={<img src={entry.productImage} alt={entry.title} style={{ width: 150, height: 150 }} />}>
            <img src={entry.productImage} alt={entry.title} style={styles.mobileProductImage} />
          </Tooltip>
          <Box flexGrow={1}>
            <Tooltip title={entry.title}>
              <Typography variant="subtitle1">{truncateTitle(entry.title)}</Typography>
            </Tooltip>
            <Typography variant="body2">Property: {entry.propertyName}</Typography>
            <Typography variant="body2">Collection: {entry.collectionName}</Typography>
            <Typography variant="body2">Status: {entry.delivered ? 'Delivered' : entry.ordered ? 'Ordered' : 'Not Ordered'}</Typography>
          </Box>
          <Checkbox checked={isSelected} onChange={() => onSelect(entry)} />
        </Box>
      </CardContent>
      <CardActions sx={styles.mobileCardActions}>
        <Button size="small" onClick={() => setExpanded(!expanded)}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          {expanded ? 'Less' : 'More'}
        </Button>
        <Box>
          <IconButton size="small" onClick={() => onEdit(entry)}>
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={() => onRemove(entry)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </CardActions>
      <Collapse in={expanded}>
        <List dense>
          <ListItem>
            <ListItemText primary="Price Paid" secondary={`${entry.pricePaid}`} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Quantity" secondary={entry.quantity || 1} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Deliver By" secondary={entry.deliverByDate} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Notes" secondary={entry.notes} />
          </ListItem>
        </List>
      </Collapse>
    </Card>
  );
};

const EntryTable = ({
  entries,
  onRemove,
  onUpdate,
  onSort,
  selectedEntries,
  setSelectedEntries,
  markAsOrdered,
  markAsDelivered,
  bulkDelete,
  moveToInventory,
  properties,
  collections,
  updateProperties,
  updateCollections,
  budgetData,
  
}) => {
  const [editingEntry, setEditingEntry] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newCollection, setNewCollection] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    console.log('Budget Data in EntryTable:', budgetData);
  }, [budgetData]);

  useEffect(() => {
  console.log('onUpdate function:', onUpdate);
  if (typeof onUpdate !== 'function') {
    console.error('onUpdate is not a function');
  }
}, [onUpdate]);
console.log('onUpdate prop received:', onUpdate);

 

const handleEdit = (entry) => {
  setEditingEntry(prevEntry => {
    console.log('Setting editing entry:', entry);
    return { ...entry };
  });
};

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setEditingEntry(prev => {
      if (!prev) return null;
      const updatedEntry = {
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      };
  
      if (name === 'pricePaid' || name === 'quantity') {
        const pricePaid = parseFloat(updatedEntry.pricePaid) || 0;
        const quantity = parseInt(updatedEntry.quantity) || 1;
        updatedEntry.spentAmount = pricePaid * quantity;
      }
  
      return updatedEntry;
    });
  };  

  const handleSave = () => {
    try {
      console.log('Attempting to save. onUpdate:', onUpdate, 'editingEntry:', editingEntry);
      if (typeof onUpdate !== 'function') {
        throw new Error('onUpdate is not a function');
      }
      if (!editingEntry) {
        throw new Error('editingEntry is null or undefined');
      }
      onUpdate(editingEntry);
      setEditingEntry(null);
      console.log('Entry saved successfully');
    } catch (error) {
      console.error('Error saving entry:', error.message);
    }
  };
  
useEffect(() => {
  console.log('Editing entry updated:', editingEntry);
}, [editingEntry]);

  
  const handleSort = (column) => {
    onSort(column, "asc");
  };

  const handleSelect = (entry) => {
    setSelectedEntries(prev =>
      prev.includes(entry) ? prev.filter(e => e !== entry) : [...prev, entry]
    );
  };

  const handleSelectAll = (event) => {
    setSelectedEntries(event.target.checked ? entries : []);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddNewCollection = () => {
    if (newCollection && editingEntry?.propertyName) {
      const newCollectionObj = { name: newCollection, budget: 0 };
      updateProperties(prevProperties => 
        prevProperties.map(property => 
          property.name === editingEntry.propertyName
            ? { ...property, collections: [...property.collections, newCollectionObj] }
            : property
        )
      );
      updateCollections(prev => [...prev, newCollectionObj]);
      setEditingEntry(prev => ({ ...prev, collectionName: newCollection }));
      setNewCollection('');
    }
  };

  const handleMarkAsDelivered = () => {
    const uniqueSelectedEntries = selectedEntries.filter((entry, index, self) =>
      index === self.findIndex((t) => 
        t.title === entry.title && 
        t.propertyName === entry.propertyName && 
        t.collectionName === entry.collectionName
      )
    );
    markAsDelivered(uniqueSelectedEntries);
    setSelectedEntries([]);
  };

  const displayEntries = entries.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const renderEditDialog = () => (
    <Dialog open={!!editingEntry} onClose={() => setEditingEntry(null)}>
      <DialogTitle>Edit Entry</DialogTitle>
      <DialogContent>
        {editingEntry && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <FormControl fullWidth>
              <InputLabel>Property Name</InputLabel>
              <Select
                name="propertyName"
                value={editingEntry.propertyName || ''}
                onChange={handleChange}
              >
                {properties.map((property) => (
                  <MenuItem key={property.name} value={property.name}>
                    {property.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
            <InputLabel>Collection Name</InputLabel>
            <Select
              name="collectionName"
              value={editingEntry?.collectionName || ''}
              onChange={handleChange}
            >
              {properties
                .find(prop => prop.name === editingEntry?.propertyName)?.collections
                .map((collection) => (
                  <MenuItem key={collection.name} value={collection.name}>
                    {collection.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

            <TextField
              label="Add New Collection"
              value={newCollection}
              onChange={(e) => setNewCollection(e.target.value)}
              fullWidth
            />
            <Button
              variant="contained"
              color="secondary"
              onClick={handleAddNewCollection}
              startIcon={<AddIcon />}
            >
              Add Collection
            </Button>
            <TextField
              label="Title"
              name="title"
              value={editingEntry.title || ''}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="URL"
              name="url"
              value={editingEntry.url || ''}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Notes"
              name="notes"
              value={editingEntry.notes || ''}
              onChange={handleChange}
              fullWidth
              multiline
            />
            <FormControlLabel
              control={
                <Switch
                  checked={editingEntry.ordered || false}
                  onChange={handleChange}
                  name="ordered"
                />
              }
              label="Ordered"
            />
            <TextField
              label="Deliver By Date"
              type="date"
              name="deliverByDate"
              value={editingEntry.deliverByDate || ''}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              label="Budget"
              type="number"
              name="budget"
              value={editingEntry.budget || ''}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Price Paid"
              type="number"
              name="pricePaid"
              value={editingEntry.pricePaid || ''}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Quantity"
              type="number"
              name="quantity"
              value={editingEntry.quantity || ''}
              onChange={handleChange}
              fullWidth
            />
            <FormControlLabel
              control={
                <Switch
                  checked={editingEntry.delivered || false}
                  onChange={handleChange}
                  name="delivered"
                />
              }
              label="Delivered"
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEditingEntry(null)}>Cancel</Button>
        <Button onClick={handleSave}>Save</Button>
      </DialogActions>
    </Dialog>
  );

  const renderMobileView = () => (
    <Box>
      {displayEntries.map((entry) => (
        <MobileEntryCard
          key={entry.id}
          entry={entry}
          onSelect={handleSelect}
          isSelected={selectedEntries.includes(entry)}
          onEdit={handleEdit}
          onRemove={onRemove}
        />
      ))}
    </Box>
  );

  const renderDesktopView = () => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={selectedEntries.length > 0 && selectedEntries.length < entries.length}
              checked={entries.length > 0 && selectedEntries.length === entries.length}
              onChange={handleSelectAll}
            />
          </TableCell>
          <TableCell onClick={() => handleSort("propertyName")}>Property Name</TableCell>
          <TableCell onClick={() => handleSort("collectionName")}>Collection Name</TableCell>
          <TableCell>Product Image</TableCell>
          <TableCell onClick={() => handleSort("title")}>Title</TableCell>
          <TableCell>URL</TableCell>
          <TableCell>Notes</TableCell>
          <TableCell onClick={() => handleSort("ordered")}>Ordered</TableCell>
          <TableCell onClick={() => handleSort("deliverByDate")}>Deliver By Date</TableCell>
          <TableCell onClick={() => handleSort("budget")}>Budget</TableCell>
          <TableCell onClick={() => handleSort("pricePaid")}>Price Paid</TableCell>
          <TableCell onClick={() => handleSort("quantity")}>Quantity</TableCell>
          <TableCell onClick={() => handleSort("delivered")}>Delivered</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {displayEntries.map((entry) => (
          <TableRow key={entry.id} style={getRowStyle(entry)}>
            <TableCell padding="checkbox">
              <Checkbox
                checked={selectedEntries.includes(entry)}
                onChange={() => handleSelect(entry)}
              />
            </TableCell>
            <TableCell>{entry.propertyName}</TableCell>
            <TableCell>{entry.collectionName}</TableCell>
            <TableCell>
              <Tooltip title={<img src={entry.productImage} alt={entry.title} style={{ width: 150, height: 150 }} />}>
                <img 
                  src={entry.productImage} 
                  alt={entry.title} 
                  style={{ 
                    width: 50, 
                    height: 50, 
                    objectFit: 'cover',
                    transition: 'transform 0.3s ease-in-out',
                    '&:hover': {
                      transform: 'scale(3)',
                      zIndex: 1
                    }
                  }} 
                />
              </Tooltip>
            </TableCell>
            <TableCell>{truncateTitle(entry.title)}</TableCell>
            <TableCell>
              <a href={entry.url} target="_blank" rel="noopener noreferrer">
                <LinkIcon />
              </a>
            </TableCell>
            <TableCell>{entry.notes}</TableCell>
            <TableCell>{entry.ordered ? 'Yes' : 'No'}</TableCell>
            <TableCell>{entry.deliverByDate}</TableCell>
            <TableCell>${entry.budget}</TableCell>
            <TableCell>${entry.pricePaid}</TableCell>
            <TableCell>{entry.quantity || 1}</TableCell>
            <TableCell>{entry.delivered ? 'Yes' : 'No'}</TableCell>
            <TableCell>
              {entry.delivered ? 'Completed' : entry.ordered ? 'In Progress' : 'Active'}
            </TableCell>
            <TableCell>
              <IconButton onClick={() => handleEdit(entry)}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => onRemove(entry)}>
                <DeleteIcon />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  const renderActionButtons = () => (
    <>
      <Button variant="contained" color="primary" size="small" onClick={() => markAsOrdered(selectedEntries.filter(entry => !entry.delivered && !entry.ordered))}>
        Mark Ordered
      </Button>
      <Button variant="contained" color="primary" size="small" onClick={handleMarkAsDelivered}>
        Mark Delivered
      </Button>
      <Button variant="contained" color="secondary" size="small" onClick={bulkDelete}>
        Delete
      </Button>
    </>
  );

  const selectedTotal = selectedEntries.reduce((total, entry) => {
    if (entry.ordered) {
      return total + (parseFloat(entry.pricePaid) * (entry.quantity || 1));
    }
    return total;
  }, 0);

  return (
    <TableContainer component={Paper} sx={styles.tableContainer}>
      {isMobile ? renderMobileView() : renderDesktopView()}
      <Toolbar sx={styles.toolbar}>
        {renderActionButtons()}
      </Toolbar>
      <Typography variant="subtitle2" sx={{ m: 1, fontSize: '0.875rem' }}>
        Selected Total: ${selectedTotal.toFixed(2)}
      </Typography>
      {renderEditDialog()}
    </TableContainer>
  );
};

export default EntryTable;

