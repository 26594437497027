import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  Checkbox, Button, TextField, Toolbar, IconButton, Select, MenuItem,
  FormControl, InputLabel, Chip, Box, Typography, Dialog, DialogTitle,
  DialogContent, DialogActions, useMediaQuery, useTheme, Tooltip,
  Card, CardContent, CardActions, Collapse, List, ListItem
} from '@mui/material';
import {
  Delete as DeleteIcon,
  ShoppingCart as ShoppingCartIcon,
  Edit as EditIcon,
  Save as SaveIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
} from '@mui/icons-material';

const styles = {
  tableContainer: { width: '100%', overflowX: 'auto' },
  table: { minWidth: 650 },
  productImage: {
    width: '50px',
    height: '50px',
    objectFit: 'cover',
    borderRadius: '4px',
    transition: 'transform 0.2s ease-in-out',
  },
  productImageHover: { transform: 'scale(3)' },
  reorderFrequencyGreen: { backgroundColor: '#d4edda' },
  reorderFrequencyYellow: { backgroundColor: '#fff3cd' },
  reorderFrequencyRed: { backgroundColor: '#f8d7da' },
  toolbar: { flexDirection: 'row', justifyContent: 'flex-start', gap: '16px', flexWrap: 'wrap' },
  formControl: { margin: '8px 0', minWidth: 120, width: '100%' },
  filterButton: { height: 'fit-content', alignSelf: 'center', marginTop: '8px' },
  mobileCard: { marginBottom: '16px' },
  mobileCardContent: { padding: '8px' },
  mobileCardActions: { justifyContent: 'space-between', padding: '8px' },
  mobileProductImage: {
    width: '50px',
    height: '50px',
    objectFit: 'cover',
    borderRadius: '4px',
    marginRight: '8px',
  },
};

const truncateTitle = (title, maxLength = 50) => {
  return title.length <= maxLength ? title : `${title.substr(0, maxLength)}...`;
};

const calculateDaysLeft = (lastDeliveryDate, reorderFrequency) => {
  const lastDate = new Date(lastDeliveryDate);
  const currentDate = new Date();
  const diffTime = Math.abs(currentDate - lastDate);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return reorderFrequency - diffDays;
};

const getColorForReorderFrequency = (daysLeft, frequency) => {
  if (daysLeft <= frequency / 3) return styles.reorderFrequencyRed;
  if (daysLeft <= (2 * frequency) / 3) return styles.reorderFrequencyYellow;
  return styles.reorderFrequencyGreen;
};

const MobileInventoryCard = ({ item, onSelect, isSelected, onEdit, onRemove }) => {
  const [expanded, setExpanded] = useState(false);
  const daysLeft = calculateDaysLeft(item.lastDeliveryDate, item.reorderFrequency || 365);

  return (
    <Card style={styles.mobileCard}>
      <CardContent style={styles.mobileCardContent}>
        <Box display="flex" alignItems="center">
          <img src={item.productImage} alt={item.title} style={styles.mobileProductImage} />
          <Box flexGrow={1}>
            <Tooltip title={item.title}>
              <Typography variant="subtitle1">{truncateTitle(item.title)}</Typography>
            </Tooltip>
            <Typography variant="body2">Property: {item.propertyName}</Typography>
            <Typography variant="body2">Collection: {item.collectionName}</Typography>
            <Typography variant="body2">In Stock: {item.numberInStock}</Typography>
          </Box>
          <Checkbox checked={isSelected} onChange={() => onSelect(item)} />
        </Box>
      </CardContent>
      <CardActions style={styles.mobileCardActions}>
        <Button
          size="small"
          onClick={() => setExpanded(!expanded)}
          startIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          {expanded ? 'Less' : 'More'}
        </Button>
        <Box>
          <IconButton size="small" onClick={() => onEdit(item)}>
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={() => onRemove(item)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      </CardActions>
      <Collapse in={expanded}>
        <List dense>
          <ListItem>
            <Typography variant="body2">Reorder Frequency: {item.reorderFrequency || 365} days</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2" style={getColorForReorderFrequency(daysLeft, item.reorderFrequency || 365)}>
              Days Left: {daysLeft}
            </Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Last Delivery: {item.lastDeliveryDate || 'N/A'}</Typography>
          </ListItem>
          <ListItem>
            <Typography variant="body2">Notes: {item.notes || 'N/A'}</Typography>
          </ListItem>
          <ListItem>
            <IconButton
              href={`https://www.amazon.com/gp/aws/cart/add.html?AssociateTag=quickshop0760-20&ASIN.1=${item.asin}&Quantity.1=${item.numberInStock}`}
              target="_blank"
            >
              <ShoppingCartIcon /> Add to Cart
            </IconButton>
          </ListItem>
        </List>
      </Collapse>
    </Card>
  );
};

const InventoryView = ({
  inventory,
  onRemove,
  onUpdate,
  onSendToList,
  onOrderSelected,
  onSaveInventory,
  propertyOptions,
  collectionOptions,
  filterInventory,
  markAsOrdered,
  bulkDelete,
  selectedItems = [],
  setSelectedItems,
}) => {
  const [filters, setFilters] = useState({ propertyNames: [], collectionNames: [] });
  const [filteredInventory, setFilteredInventory] = useState([]);
  const [editItem, setEditItem] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
  if (Array.isArray(inventory)) {
    const newFilteredInventory = inventory.filter(item => 
      (filters.propertyNames.length === 0 || filters.propertyNames.includes(item.propertyName)) &&
      (filters.collectionNames.length === 0 || filters.collectionNames.includes(item.collectionName))
    );
    setFilteredInventory(newFilteredInventory);
  } else {
    setFilteredInventory([]);
  }
}, [filters, inventory]);

  const handleSelect = (item) => {
    setSelectedItems((prevSelected) =>
      prevSelected.some(i => 
        i.title === item.title && 
        i.propertyName === item.propertyName && 
        i.collectionName === item.collectionName
      )
        ? prevSelected.filter(i => 
            i.title !== item.title || 
            i.propertyName !== item.propertyName || 
            i.collectionName !== item.collectionName
          )
        : [...prevSelected, item]
    );
  };

  const handleSelectAll = () => {
    if (filteredInventory && filteredInventory.length > 0) {
      setSelectedItems(selectedItems.length === filteredInventory.length ? [] : filteredInventory);
    }
  };  

  const handleEditClick = (item) => {
    setEditItem(item);
    setIsEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    setEditItem(null);
  };

  const handleEditModalSave = () => {
    onUpdate(editItem);
    setIsEditModalOpen(false);
    setEditItem(null);
  };

  const handleInputChange = (field, value) => {
    setEditItem((prevItem) => ({
      ...prevItem,
      [field]: field === 'numberInStock' || field === 'reorderFrequency' ? parseInt(value) || 0 : value,
    }));
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: typeof value === 'string' ? value.split(',') : value,
    }));
  };

  const clearFilter = (filterName) => {
    setFilters((prevFilters) => ({ ...prevFilters, [filterName]: [] }));
  };

  const clearAllFilters = () => {
    setFilters({ propertyNames: [], collectionNames: [] });
  };

  const renderFilterControls = () => (
    <>
      <FormControl style={styles.formControl}>
        <InputLabel>Filter by Property</InputLabel>
        <Select
          multiple
          value={filters.propertyNames}
          onChange={handleFilterChange}
          name="propertyNames"
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {propertyOptions && propertyOptions.filter(Boolean).map((option) => (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {filters.propertyNames.length > 0 && (
        <Button
          onClick={() => clearFilter('propertyNames')}
          color="secondary"
          style={styles.filterButton}
        >
          Clear Property Filter
        </Button>
      )}
      <FormControl style={styles.formControl}>
        <InputLabel>Filter by Collection</InputLabel>
        <Select
          multiple
          value={filters.collectionNames}
          onChange={handleFilterChange}
          name="collectionNames"
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
        >
          {collectionOptions && collectionOptions.filter(option => option && option.name && option.name.trim() !== '').map((option) => (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {filters.collectionNames.length > 0 && (
        <Button
          onClick={() => clearFilter('collectionNames')}
          color="secondary"
          style={styles.filterButton}
        >
          Clear Collection Filter
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={clearAllFilters}
        style={styles.filterButton}
      >
        Clear All Filters
      </Button>
    </>
  );

  const renderInventoryTable = () => (
    <Table style={styles.table}>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
            indeterminate={selectedItems && filteredInventory && selectedItems.length > 0 && selectedItems.length < filteredInventory.length}
            checked={filteredInventory && filteredInventory.length > 0 && selectedItems && selectedItems.length === filteredInventory.length}
            onChange={handleSelectAll}
          />

          </TableCell>
          <TableCell>Property Name</TableCell>
          <TableCell>Collection Name</TableCell>
          <TableCell>Product Title</TableCell>
          <TableCell>Product Image</TableCell>
          <TableCell>Reorder Frequency (Days)</TableCell>
          <TableCell>Days Left</TableCell>
          <TableCell>Last Delivery Date</TableCell>
          <TableCell>Number in Stock</TableCell>
          <TableCell>Add To Cart</TableCell>
          <TableCell>Notes</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
  {filteredInventory.map((item, index) => {
    const daysLeft = calculateDaysLeft(item.lastDeliveryDate, item.reorderFrequency || 365);
    return (
      <TableRow key={index}>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedItems && selectedItems.includes(item)}
            onChange={() => handleSelect(item)}
          />
        </TableCell>
              <TableCell>{item.propertyName || ''}</TableCell>
              <TableCell>{item.collectionName || ''}</TableCell>
              <TableCell>
                <Tooltip title={item.title}>
                  <Typography>{truncateTitle(item.title)}</Typography>
                </Tooltip>
              </TableCell>
              <TableCell>
                <img
                  src={item.productImage}
                  alt={item.title}
                  style={styles.productImage}
                  onMouseOver={(e) => e.target.style.transform = 'scale(3)'}
                  onMouseOut={(e) => e.target.style.transform = 'scale(1)'}
                />
              </TableCell>
              <TableCell>{item.reorderFrequency || 365}</TableCell>
              <TableCell
                style={getColorForReorderFrequency(daysLeft, item.reorderFrequency || 365)}
              >
                {daysLeft}
              </TableCell>
              <TableCell>{item.lastDeliveryDate || ''}</TableCell>
              <TableCell>{item.numberInStock || ''}</TableCell>
              <TableCell>
                <IconButton
                  href={`https://www.amazon.com/gp/aws/cart/add.html?AssociateTag=quickshop0760-20&ASIN.1=${item.asin}&Quantity.1=${item.numberInStock}`}
                  target="_blank"
                >
                  <ShoppingCartIcon />
                </IconButton>
              </TableCell>
              <TableCell>{item.notes || ''}</TableCell>
              <TableCell>
                <IconButton onClick={() => handleEditClick(item)} color="primary">
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onRemove(item)} color="secondary">
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  const renderActionButtons = () => (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => onSendToList(selectedItems)}
        style={styles.filterButton}
      >
        Send To List
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => onOrderSelected(selectedItems)}
        style={styles.filterButton}
      >
        Order Selected
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => markAsOrdered(selectedItems)}
        style={styles.filterButton}
      >
        Mark Ordered
      </Button>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => bulkDelete(selectedItems)}
        style={styles.filterButton}
      >
        Delete Selected
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => onSaveInventory(filteredInventory)}
        style={styles.filterButton}
      >
        <SaveIcon /> Save
      </Button>
    </>
  );

  return (
    <TableContainer component={Paper} style={styles.tableContainer}>
      <Toolbar style={styles.toolbar}>
        {renderFilterControls()}
      </Toolbar>
      {isMobile ? (
        filteredInventory.map((item) => (
          <MobileInventoryCard
            key={item.id}
            item={item}
            onSelect={handleSelect}
            isSelected={selectedItems.includes(item)}
            onEdit={handleEditClick}
            onRemove={onRemove}
          />
        ))
      ) : (
        renderInventoryTable()
      )}
      <Toolbar style={styles.toolbar}>
        {renderActionButtons()}
      </Toolbar>
      <Dialog open={isEditModalOpen} onClose={handleEditModalClose}>
        <DialogTitle>Edit Inventory Item</DialogTitle>
        <DialogContent>
          {editItem && (
            <>
              <TextField
                label="Property Name"
                value={editItem.propertyName || ''}
                onChange={(e) => handleInputChange('propertyName', e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Collection Name"
                value={editItem.collectionName || ''}
                onChange={(e) => handleInputChange('collectionName', e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Reorder Frequency (Days)"
                type="number"
                value={editItem.reorderFrequency || 365}
                onChange={(e) => handleInputChange('reorderFrequency', e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Last Delivery Date"
                type="date"
                value={editItem.lastDeliveryDate || ''}
                onChange={(e) => handleInputChange('lastDeliveryDate', e.target.value)}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Number in Stock"
                type="number"
                value={editItem.numberInStock || ''}
                onChange={(e) => handleInputChange('numberInStock', e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Notes"
                value={editItem.notes || ''}
                onChange={(e) => handleInputChange('notes', e.target.value)}
                fullWidth
                margin="normal"
                multiline
                rows={4}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditModalClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleEditModalSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </TableContainer>
  );
};

export default InventoryView;