import { normalize, schema } from 'normalizr';

const collectionSchema = new schema.Entity('collections', {}, {
  idAttribute: 'name',
  processStrategy: (value, parent, key) => ({
    ...value,
    budget: value.budget || 0
  })
});

const propertySchema = new schema.Entity('properties', {
  collections: [collectionSchema]
}, {
  idAttribute: 'name',
  processStrategy: (value, parent, key) => ({
    ...value,
    collections: (value.collections || []).filter(c => c != null).map(c => c.name || ''),
    icalLink: value.icalLink || ''
  })
});

const entrySchema = new schema.Entity('entries', {}, {
  idAttribute: 'id',
  processStrategy: (value, parent, key) => ({
    ...value,
    quantity: value.quantity || 1,
    ordered: value.ordered || false,
    delivered: value.delivered || false,
    spentAmount: value.spentAmount || 0,
    pricePaid: value.pricePaid || 0,
    budget: value.budget || 0,
    notes: value.notes || '',
    deliverByDate: value.deliverByDate || '',
    productImage: value.productImage || '',
  })
});

const inventorySchema = new schema.Entity('inventory', {}, {
  idAttribute: (entity) => `${entity.title}-${entity.propertyName}-${entity.collectionName}`,
  processStrategy: (value, parent, key) => ({
    ...value,
    numberInStock: value.numberInStock || 0,
    reorderFrequency: value.reorderFrequency || 365,
    spentAmount: value.spentAmount || 0,
    ordered: value.ordered || false,
    delivered: value.delivered || false
  })
});

  const maintenanceItemSchema = new schema.Entity('maintenanceItems', {
    properties: [propertySchema]
  }, {
    idAttribute: 'id',
    processStrategy: (value, parent, key) => ({
      ...value,
      frequency: value.frequency || 'once',
      frequencyDays: value.frequencyDays || null,
      severity: value.severity || '',
      status: value.status || 'active',
      startDate: value.startDate || null, // Add this line
    })
  });


const budgetDataSchema = new schema.Entity('budgetData', {}, {
  idAttribute: 'collectionName',
  processStrategy: (value, parent, key) => ({
    ...value,
    spent: value.spent || 0,
    ordered: value.ordered || 0
  })
});

const providerSchema = new schema.Entity('providers', {
  properties: [propertySchema]
}, {
  idAttribute: 'id',
  processStrategy: (value, parent, key) => ({
    ...value,
    phone: value.phone || '',
    email: value.email || '',
    address: value.address || ''
  })
});

const damageSchema = new schema.Entity('damages', {}, {
  idAttribute: 'id',
  processStrategy: (value, parent, key) => ({
    ...value,
    date: value.date || new Date().toISOString().split('T')[0],
    propertyName: value.propertyName || '',
    providerName: value.providerName || '',
    items: value.items || [],
    invoiceItems: value.invoiceItems || [],
    invoicePdf: value.invoicePdf || ''
  })
});

export const normalizeData = (data) => {
  const normalizedData = normalize(data, {
    properties: [propertySchema],
    collections: [collectionSchema],
    entries: [entrySchema],
    inventory: [inventorySchema],
    maintenanceItems: [maintenanceItemSchema],
    budgetData: [budgetDataSchema],
    providers: [providerSchema],
    damages: [damageSchema]
  });
  
  normalizedData.result.properties = normalizedData.result.properties || [];
  normalizedData.result.collections = normalizedData.result.collections || [];
  normalizedData.result.entries = normalizedData.result.entries || [];
  normalizedData.result.inventory = normalizedData.result.inventory || [];
  normalizedData.result.maintenanceItems = normalizedData.result.maintenanceItems || [];
  normalizedData.result.budgetData = normalizedData.result.budgetData || [];
  normalizedData.result.providers = normalizedData.result.providers || [];
  normalizedData.result.damages = normalizedData.result.damages || [];
  
  return normalizedData;
};

export const denormalizeData = (normalizedData) => {
  if (!normalizedData || typeof normalizedData !== 'object') {
    return {
      entries: [],
      inventory: [],
      properties: [],
      collections: [],
      maintenanceItems: [],
      budgetData: {},
      providers: [],
      damages: [],
    };
  }

  const { entities = {}, result = {} } = normalizedData;
  
  const denormalizedProperties = result.properties.map(id => {
    const property = entities.properties[id] || {};
    return {
      ...property,
      collections: (property.collections || []).map(collectionId => entities.collections[collectionId] || {}),
      icalLink: property.icalLink || ''
    };
  });

  const denormalizedEntries = result.entries.map(id => {
    const entry = entities.entries[id] || {};
    return {
      ...entry,
      quantity: entry.quantity || 1,
      ordered: entry.ordered || false,
      delivered: entry.delivered || false,
      spentAmount: entry.spentAmount || 0
    };
  });

  const denormalizedInventory = result.inventory.map(id => {
    const item = entities.inventory[id] || {};
    return {
      ...item,
      id: item.id || `${item.title}-${item.propertyName}-${item.collectionName}`,
      numberInStock: item.numberInStock || 0,
      reorderFrequency: item.reorderFrequency || 365,
      spentAmount: item.spentAmount || 0,
      ordered: item.ordered || false,
      delivered: item.delivered || false
    };
  });

  const denormalizedCollections = result.collections.map(id => entities.collections[id] || {});

  const denormalizedMaintenanceItems = result.maintenanceItems.map(id => {
    const item = entities.maintenanceItems[id] || {};
    return {
      ...item,
      properties: (item.properties || []).map(propertyId => entities.properties[propertyId] || {})
    };
  });

  const denormalizedBudgetData = Array.isArray(result.budgetData)
    ? result.budgetData.reduce((acc, id) => {
        const budgetItem = entities.budgetData[id] || {};
        acc[budgetItem.collectionName] = {
          spent: budgetItem.spent || 0,
          ordered: budgetItem.ordered || 0
        };
        return acc;
      }, {})
    : Object.keys(entities.budgetData || {}).reduce((acc, id) => {
        const budgetItem = entities.budgetData[id];
        acc[budgetItem.collectionName] = {
          spent: budgetItem.spent || 0,
          ordered: budgetItem.ordered || 0
        };
        return acc;
      }, {});

  const denormalizedProviders = result.providers && result.providers.map(id => {
    const provider = entities.providers[id] || {};
    return {
      ...provider,
      properties: (provider.properties || []).map(propertyId => entities.properties[propertyId] || {})
    };
  }) || [];

  const denormalizedDamages = result.damages && result.damages.map(id => {
    const damage = entities.damages[id] || {};
    return {
      ...damage,
      date: damage.date || new Date().toISOString().split('T')[0],
      propertyName: damage.propertyName || '',
      providerName: damage.providerName || '',
      items: damage.items || [],
      invoiceItems: damage.invoiceItems || [],
      invoicePdf: damage.invoicePdf || ''
    };
  }) || [];

  return {
    entries: denormalizedEntries,
    inventory: denormalizedInventory,
    properties: denormalizedProperties,
    collections: denormalizedCollections,
    maintenanceItems: denormalizedMaintenanceItems,
    budgetData: denormalizedBudgetData,
    providers: denormalizedProviders,
    damages: denormalizedDamages
  };
};
