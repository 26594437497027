import React, { useState, useEffect } from 'react';
import {
  Box, Tab, Tabs, Typography, TextField, Button, Grid, Card, CardContent,
  CardMedia, IconButton, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, Select, MenuItem, FormControl, InputLabel, Accordion,
  AccordionSummary, AccordionDetails, useMediaQuery, useTheme, Dialog, DialogTitle,
  DialogContent, DialogActions, Checkbox, Chip
} from '@mui/material';
import {
  Add as AddIcon, Delete as DeleteIcon, ExpandMore as ExpandMoreIcon,
  Edit as EditIcon, Download as DownloadIcon, Visibility as VisibilityIcon
} from '@mui/icons-material';
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const styles = {
  tableContainer: { width: '100%', overflowX: 'auto' },
  table: { minWidth: 650 },
  mobileCard: { marginBottom: '16px' },
  mobileCardContent: { padding: '8px' },
  mobileCardActions: { justifyContent: 'space-between', padding: '8px' },
  mobileImage: { width: '50px', height: '50px', objectFit: 'cover', borderRadius: '4px', marginRight: '8px' },
};

const DamagesView = ({ damages, onAddDamage, onDeleteDamage, onUpdateDamage, properties, providers }) => {
  const [tabValue, setTabValue] = useState(0);
  const [damageItems, setDamageItems] = useState([{ photos: [], description: '' }]);
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedProvider, setSelectedProvider] = useState('');
  const [invoiceItems, setInvoiceItems] = useState([{ description: '', amount: 0 }]);
  const [expandedAccordion, setExpandedAccordion] = useState(false);
  const [selectedDamage, setSelectedDamage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDamages, setSelectedDamages] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [bookingUrl, setBookingUrl] = useState('');
  const [initialNote, setInitialNote] = useState('');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handlePhotoUpload = async (event, index) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      const newDamageItems = [...damageItems];
      for (const file of files) {
        try {
          const formData = new FormData();
          formData.append('image', file);
          const response = await fetch('https://api.stayfu.com/api/upload-image', {
            method: 'POST',
            body: formData,
          });
          const { imagePath } = await response.json();
          newDamageItems[index].photos.push(imagePath);
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      }
      setDamageItems([...newDamageItems]);
    }
  };

  const handleDescriptionChange = (event, index) => {
    const newDamageItems = [...damageItems];
    newDamageItems[index].description = event.target.value;
    setDamageItems(newDamageItems);
  };

  const handleAddMore = () => {
    setDamageItems([...damageItems, { photos: [], description: '' }]);
  };

  const fetchLastBookingUrl = (propertyName) => {
    const property = properties.find(p => p.name === propertyName);
    if (property && property.calendarData && property.calendarData.length > 0) {
      const now = new Date();
      const pastBookings = property.calendarData
        .filter(event => new Date(event.end) < now)
        .sort((a, b) => new Date(b.end) - new Date(a.end));
      
      if (pastBookings.length > 0) {
        return pastBookings[0].reservationUrl || '';
      }
    }
    return '';
  };

  const handlePropertyChange = (e) => {
    const selectedPropertyName = e.target.value;
    setSelectedProperty(selectedPropertyName);
    const lastBookingUrl = fetchLastBookingUrl(selectedPropertyName);
    setBookingUrl(lastBookingUrl);
  };

  const handleSave = () => {
    const newDamage = {
      id: Date.now().toString(),
      date,
      propertyName: selectedProperty,
      providerName: selectedProvider,
      items: damageItems,
      invoiceItems,
      invoicePdf: generateInvoicePdf(),
      status: 'New',
      notes: [{ text: initialNote, timestamp: new Date().toISOString() }],
      bookingUrl,
    };
    onAddDamage(newDamage);
    resetForm();
  };

  const resetForm = () => {
    setDamageItems([{ photos: [], description: '' }]);
    setDate(new Date().toISOString().split('T')[0]);
    setSelectedProperty('');
    setSelectedProvider('');
    setInvoiceItems([{ description: '', amount: 0 }]);
    setExpandedAccordion(false);
    setBookingUrl('');
    setInitialNote('');
  };

  const handleRemovePhoto = (itemIndex, photoIndex) => {
    const newDamageItems = [...damageItems];
    newDamageItems[itemIndex].photos.splice(photoIndex, 1);
    setDamageItems(newDamageItems);
  };

  const handleAddInvoiceItem = () => {
    setInvoiceItems([...invoiceItems, { description: '', amount: 0 }]);
  };

  const handleInvoiceItemChange = (index, field, value) => {
    const newInvoiceItems = [...invoiceItems];
    newInvoiceItems[index][field] = value;
    setInvoiceItems(newInvoiceItems);
  };

  const generateInvoicePdf = () => {
    const doc = new jsPDF();
    const provider = providers.find(p => p.name === selectedProperty);
    
    doc.text(`Invoice for ${selectedProperty}`, 20, 20);
    doc.text(`Date: ${date}`, 20, 30);
    doc.text(`Provider: ${selectedProvider}`, 20, 40);
    if (provider) {
      doc.text(`${provider.address}`, 20, 50);
      doc.text(`Phone: ${provider.phone}`, 20, 60);
      doc.text(`Email: ${provider.email}`, 20, 70);
    }

    const tableData = invoiceItems.map(item => [item.description, `${item.amount.toFixed(2)}`]);
    const total = invoiceItems.reduce((sum, item) => sum + item.amount, 0);
    tableData.push(['Total', `${total.toFixed(2)}`]);

    doc.autoTable({
      startY: 80,
      head: [['Description', 'Amount']],
      body: tableData,
    });

    doc.addPage();

    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 20;
    const imageSize = (pageWidth - 3 * margin) / 2;
    const imagesPerPage = 4;

    damageItems.forEach((item, index) => {
      if (index > 0 && index % imagesPerPage === 0) {
        doc.addPage();
      }

      const pageIndex = Math.floor(index / imagesPerPage);
      const itemIndex = index % imagesPerPage;

      const x = margin + (itemIndex % 2) * (imageSize + margin);
      const y = margin + Math.floor(itemIndex / 2) * (imageSize + margin + 40);

      if (item.photos[0]) {
        doc.addImage(item.photos[0], 'JPEG', x, y, imageSize, imageSize);
      }

      doc.setFontSize(10);
      doc.text(item.description, x, y + imageSize + 10, { maxWidth: imageSize });
    });

    return doc.output('datauristring');
  };

  const handleEditDamage = (damage) => {
    setTabValue(1);
    setDamageItems(damage.items);
    setDate(damage.date);
    setSelectedProperty(damage.propertyName);
    setSelectedProvider(damage.providerName);
    setInvoiceItems(damage.invoiceItems);
    setBookingUrl(damage.bookingUrl || fetchLastBookingUrl(damage.propertyName));
    setInitialNote(damage.notes[0]?.text || '');
  };

  const handleDownloadInvoice = (invoicePdf) => {
    const link = document.createElement('a');
    link.href = invoicePdf;
    link.download = 'invoice.pdf';
    link.click();
  };

  const handleDeleteDamage = (damageId) => {
    onDeleteDamage(damageId);
  };

  const handleViewDamage = (damage) => {
    setSelectedDamage(damage);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedDamage(null);
    setNewNote('');
  };

  const handleCheckDamage = (damageId) => {
    setSelectedDamages(prevSelected => 
      prevSelected.includes(damageId)
        ? prevSelected.filter(id => id !== damageId)
        : [...prevSelected, damageId]
    );
  };

  const handleStatusChange = (damageId, newStatus) => {
    const updatedDamage = damages.find(damage => damage.id === damageId);
    if (updatedDamage) {
      updatedDamage.status = newStatus;
      onUpdateDamage(updatedDamage);
    }
  };

  const handleAddNote = () => {
    if (newNote.trim() && selectedDamage) {
      const updatedDamage = {
        ...selectedDamage,
        notes: [
          ...selectedDamage.notes,
          { text: newNote, timestamp: new Date().toISOString() }
        ]
      };
      onUpdateDamage(updatedDamage);
      setNewNote('');
      setSelectedDamage(updatedDamage);
    }
  };

  const renderMobileDamageCard = (damage, index) => (
    <Card key={index} style={styles.mobileCard}>
      <CardContent style={styles.mobileCardContent}>
        <Typography variant="h6">{damage.propertyName}</Typography>
        <Typography variant="body2">Date: {damage.date}</Typography>
        <Typography variant="body2">Provider: {damage.providerName}</Typography>
        <Box display="flex" alignItems="center" mt={1}>
          {damage.items && damage.items[0] && damage.items[0].photos && damage.items[0].photos[0] && (
            <img src={damage.items[0].photos[0]} alt="Damage" style={styles.mobileImage} />
          )}
          <Typography variant="body2">{damage.notes && damage.notes[0] ? `${new Date(damage.notes[0].timestamp).toLocaleString()}: ${damage.notes[0].text}` : 'No notes'}</Typography>
        </Box>
        <Chip 
          label={damage.status} 
          color={damage.status === 'New' ? 'error' : damage.status === 'Pending' ? 'warning' : 'default'}
          size="small"
          sx={{ mt: 1 }}
        />
      </CardContent>
      <Box display="flex" justifyContent="flex-end" p={1}>
        <IconButton size="small" onClick={() => handleViewDamage(damage)}>
          <VisibilityIcon />
        </IconButton>
        <IconButton size="small" onClick={() => handleEditDamage(damage)}>
          <EditIcon />
        </IconButton>
        <IconButton size="small" onClick={() => handleDownloadInvoice(damage.invoicePdf)}>
          <DownloadIcon />
        </IconButton>
        <IconButton size="small" onClick={() => handleDeleteDamage(damage.id)}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Card>
  );

  const renderDamagesTable = () => (
    <TableContainer component={Paper} style={styles.tableContainer}>
      <Table style={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selectedDamages.length > 0 && selectedDamages.length < damages.length}
                checked={damages.length > 0 && selectedDamages.length === damages.length}
                onChange={() => {
                  if (selectedDamages.length === damages.length) {
                    setSelectedDamages([]);
                  } else {
                    setSelectedDamages(damages.map(d => d.id));
                  }
                }}
              />
            </TableCell>
            <TableCell>Property</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Notes</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {damages.map((damage, index) => (
            <TableRow key={index}>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedDamages.includes(damage.id)}
                  onChange={() => handleCheckDamage(damage.id)}
                />
              </TableCell>
              <TableCell>{damage.propertyName}</TableCell>
              <TableCell>{damage.date}</TableCell>
              <TableCell>{damage.providerName}</TableCell>
              <TableCell>{damage.notes && damage.notes[0] ? `${new Date(damage.notes[0].timestamp).toLocaleString()}: ${damage.notes[0].text}` : 'No notes'}</TableCell>
              <TableCell>
                <Select
                  value={damage.status}
                  onChange={(e) => handleStatusChange(damage.id, e.target.value)}
                  size="small"
                >
                  <MenuItem value="New">
                    <Chip label="New" color="error" size="small" />
                  </MenuItem>
                  <MenuItem value="Pending">
                    <Chip label="Pending" color="warning" size="small" />
                  </MenuItem>
                  <MenuItem value="Completed">
                    <Chip label="Completed" color="default" size="small" />
                  </MenuItem>
                </Select>
              </TableCell>
              <TableCell>
                <IconButton size="small" onClick={() => handleViewDamage(damage)}>
                  <VisibilityIcon />
                </IconButton>
                <IconButton size="small" onClick={() => handleEditDamage(damage)}>
                  <EditIcon />
                </IconButton>
                <IconButton size="small" onClick={() => handleDownloadInvoice(damage.invoicePdf)}>
                  <DownloadIcon />
                </IconButton>
                <IconButton size="small" onClick={() => handleDeleteDamage(damage.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderDamageDialog = () => (
    <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
      <DialogTitle>Damage Details</DialogTitle>
      <DialogContent>
        {selectedDamage && (
          <Box>
            <Typography variant="h6">{selectedDamage.propertyName}</Typography>
            <Typography variant="body1">Date: {selectedDamage.date}</Typography>
            <Typography variant="body1">Provider: {selectedDamage.providerName}</Typography>
            <Typography variant="body1">Status: {selectedDamage.status}</Typography>
            <Typography variant="body1">Booking URL: {selectedDamage.bookingUrl}</Typography>
            {selectedDamage.items.map((item, index) => (
              <Box key={index} mt={2}>
                <Typography variant="subtitle1">Damage Item {index + 1}</Typography>
                <Grid container spacing={2}>
                  {item.photos.map((photo, photoIndex) => (
                    <Grid item key={photoIndex} xs={6} sm={3}>
                      <img src={photo} alt={`Damage ${photoIndex + 1}`} style={{ width: '100%', height: 'auto' }} />
                    </Grid>
                  ))}
                </Grid>
                <Typography variant="body2" mt={1}>{item.description}</Typography>
              </Box>
            ))}
            <Typography variant="h6" mt={2}>Notes</Typography>
            {selectedDamage.notes.map((note, index) => (
              <Box key={index} mt={1}>
                <Typography variant="body2">{new Date(note.timestamp).toLocaleString()}: {note.text}</Typography>
              </Box>
            ))}
            <Box mt={2}>
              <TextField
                fullWidth
                multiline
                rows={2}
                variant="outlined"
                label="Add a note"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
              />
              <Button variant="contained" onClick={handleAddNote} sx={{ mt: 1 }}>
                Add Note
              </Button>
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Close</Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={tabValue} onChange={handleTabChange}>
        <Tab label="Current Damages" />
        <Tab label="Add Damages" />
      </Tabs>
      <Box sx={{ p: 3 }}>
        {tabValue === 0 && (
          <>
            {isMobile ? (
              damages.map((damage, index) => renderMobileDamageCard(damage, index))
            ) : (
              renderDamagesTable()
            )}
            {renderDamageDialog()}
          </>
        )}
        {tabValue === 1 && (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  label="Date"
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Property</InputLabel>
                  <Select
                    value={selectedProperty}
                    onChange={handlePropertyChange}
                  >
                    {properties.map((property) => (
                      <MenuItem key={property.name} value={property.name}>
                        {property.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <InputLabel>Provider</InputLabel>
                  <Select
                    value={selectedProvider}
                    onChange={(e) => setSelectedProvider(e.target.value)}
                  >
                    {providers.map((provider) => (
                      <MenuItem key={provider.name} value={provider.name}>
                        {provider.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Booking URL"
                  value={bookingUrl}
                  onChange={(e) => setBookingUrl(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Initial Note"
                  value={initialNote}
                  onChange={(e) => setInitialNote(e.target.value)}
                />
              </Grid>
            </Grid>
            {damageItems.map((item, index) => (
              <Box key={index} sx={{ mb: 4 }}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id={`photo-upload-${index}`}
                  type="file"
                  multiple
                  onChange={(e) => handlePhotoUpload(e, index)}
                />
                <label htmlFor={`photo-upload-${index}`}>
                  <Button variant="contained" component="span">
                    Upload Photos
                  </Button>
                </label>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {item.photos.map((photo, photoIndex) => (
                    <Grid item key={photoIndex}>
                      <Card>
                        <CardMedia
                          component="img"
                          height="140"
                          image={photo}
                          alt={`Uploaded photo ${photoIndex + 1}`}
                        />
                        <IconButton
                          size="small"
                          onClick={() => handleRemovePhoto(index, photoIndex)}
                          sx={{ position: 'absolute', top: 5, right: 5, color: 'white' }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Card>
                    </Grid>
                  ))}
                </Grid>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Description"
                  value={item.description}
                  onChange={(e) => handleDescriptionChange(e, index)}
                  sx={{ mt: 2 }}
                />
              </Box>
            ))}
            <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddMore} sx={{ mr: 2 }}>
              Add More Damage Items
            </Button>
            <Accordion
              expanded={expandedAccordion}
              onChange={() => setExpandedAccordion(!expandedAccordion)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Invoice Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {invoiceItems.map((item, index) => (
                  <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
                    <Grid item xs={8}>
                      <TextField
                        fullWidth
                        label="Description"
                        value={item.description}
                        onChange={(e) => handleInvoiceItemChange(index, 'description', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        fullWidth
                        label="Amount"
                        type="number"
                        value={item.amount}
                        onChange={(e) => handleInvoiceItemChange(index, 'amount', parseFloat(e.target.value))}
                      />
                    </Grid>
                  </Grid>
                ))}
                <Button variant="outlined" startIcon={<AddIcon />} onClick={handleAddInvoiceItem}>
                  Add Invoice Item
                </Button>
              </AccordionDetails>
            </Accordion>
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={damageItems.some(item => item.photos.length === 0 || !item.description) || !selectedProperty || !selectedProvider}
              sx={{ mt: 2 }}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DamagesView;
