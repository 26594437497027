import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Paper,
  Avatar,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon, Image as ImageIcon } from '@mui/icons-material';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

const PropertyManagementView = ({ properties, collections, updateProperties, updateCollections }) => {
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [editingItem, setEditingItem] = useState(null);
  const [newPropertyName, setNewPropertyName] = useState('');
  const [newCollectionName, setNewCollectionName] = useState('');
  const [propertyImage, setPropertyImage] = useState(null);
  const [icalLink, setIcalLink] = useState('');
  const [budget, setBudget] = useState('');
  const [calendarEvents, setCalendarEvents] = useState([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (selectedProperty && selectedProperty.icalLink) {
      fetchCalendarEvents(selectedProperty.icalLink);
    }
  }, [selectedProperty]);

  const fetchCalendarEvents = async (icalUrl) => {
    try {
      const response = await fetch(`/api/fetch-ical?icalUrl=${encodeURIComponent(icalUrl)}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const events = await response.json();
      
      const formattedEvents = events.flatMap(event => {
        const startDate = new Date(event.start);
        const endDate = new Date(event.end);

        return [
          {
            title: 'Reserved',
            start: startDate,
            end: new Date(endDate.getTime() + 86400000),
            display: 'background',
            color: '#F44336',
            rendering: 'background',
            allDay: true
          },
          {
            title: 'Check-in',
            start: startDate,
            end: startDate,
            extendedProps: {
              isCheckIn: true,
              description: event.description,
              reservationUrl: event.reservationUrl,
              phoneLastFourDigits: event.phoneLastFourDigits,
              bookingStart: startDate,
              bookingEnd: endDate,
            },
            color: '#4CAF50',
            allDay: true
          },
          {
            title: 'Check-out',
            start: endDate,
            end: endDate,
            extendedProps: {
              isCheckOut: true,
              description: event.description,
              reservationUrl: event.reservationUrl,
              phoneLastFourDigits: event.phoneLastFourDigits,
              bookingStart: startDate,
              bookingEnd: endDate,
            },
            color: '#FFC107',
            allDay: true
          }
        ];
      });

      console.log('Formatted events:', formattedEvents);
      setCalendarEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching or parsing calendar events:', error);
      setCalendarEvents([]);
    }
  };

  const handleAddProperty = () => {
    if (newPropertyName.trim()) {
      const newProperty = { 
        name: newPropertyName.trim(), 
        collections: [],
        image: propertyImage || '',
        icalLink: icalLink || ''
      };
      updateProperties([...properties, newProperty]);
      setNewPropertyName('');
      setPropertyImage('');
      setIcalLink('');
    }
  };

  const handleAddCollection = () => {
    if (newCollectionName.trim() && selectedProperty) {
      const newCollection = { name: newCollectionName.trim(), budget: parseFloat(budget) || 0 };
      const updatedProperties = properties.map(property => 
        property === selectedProperty
          ? { ...property, collections: [...property.collections, newCollection] }
          : property
      );
      updateProperties(updatedProperties);
      updateCollections([...collections, newCollection]);
      setNewCollectionName('');
      setBudget('');
      
      const updatedSelectedProperty = updatedProperties.find(p => p === selectedProperty);
      setSelectedProperty(updatedSelectedProperty);
    }
  };

  const handleEditItem = (item, type) => {
    setEditingItem(item);
    setDialogType(`edit${type}`);
    setOpenDialog(true);
    if (type === 'Property') {
      setNewPropertyName(item.name);
      setPropertyImage(item.image || '');
      setIcalLink(item.icalLink || '');
    } else if (type === 'Collection') {
      setNewCollectionName(item.name);
      setBudget(item.budget ? item.budget.toString() : '');
    }
  };

  const handleDeleteItem = (itemToDelete, type) => {
    if (type === 'property') {
      const updatedProperties = properties.filter(property => property !== itemToDelete);
      updateProperties(updatedProperties);
      if (selectedProperty === itemToDelete) {
        setSelectedProperty(updatedProperties[0] || null);
      }
    } else if (type === 'collection') {
      const updatedProperties = properties.map(property => 
        property === selectedProperty
          ? { ...property, collections: property.collections.filter(c => c !== itemToDelete) }
          : property
      );
      updateProperties(updatedProperties);
      const updatedCollections = collections.filter(c => c.name !== itemToDelete.name);
      updateCollections(updatedCollections);
    }
  };

  const handleSaveEdit = () => {
    if (dialogType === 'editProperty') {
      const updatedProperties = properties.map(property =>
        property === editingItem ? { 
          ...property, 
          name: newPropertyName || property.name,
          image: propertyImage || '',
          icalLink: icalLink || ''
        } : property
      );
      updateProperties(updatedProperties);
      if (selectedProperty === editingItem) {
        setSelectedProperty({ 
          ...selectedProperty, 
          name: newPropertyName || selectedProperty.name,
          image: propertyImage || '',
          icalLink: icalLink || ''
        });
      }
    } else if (dialogType === 'editCollection') {
      const updatedProperties = properties.map(property => ({
        ...property,
        collections: property.collections.map(collection =>
          collection === editingItem ? { ...collection, name: newCollectionName || collection.name, budget: parseFloat(budget) || 0 } : collection
        ),
      }));
      updateProperties(updatedProperties);
      const updatedCollections = collections.map(collection =>
        collection === editingItem ? { ...collection, name: newCollectionName || collection.name, budget: parseFloat(budget) || 0 } : collection
      );
      updateCollections(updatedCollections);
    }
    handleCloseDialog();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingItem(null);
    setNewPropertyName('');
    setNewCollectionName('');
    setPropertyImage('');
    setIcalLink('');
    setBudget('');
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPropertyImage(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const renderPropertyList = () => (
    <List>
      {Array.isArray(properties) && properties.map((property, index) => (
        <ListItem
          key={index}
          button
          selected={property === selectedProperty}
          onClick={() => setSelectedProperty(property)}
        >
          {property.image && <Avatar src={property.image} style={{ marginRight: '10px' }} />}
          <ListItemText 
            primary={property.name} 
            secondary={property.icalLink ? 'iCal linked' : 'No iCal link'}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="edit" onClick={() => handleEditItem(property, 'Property')}>
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteItem(property, 'property')}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );

  const renderCollectionList = () => (
    <List>
      {selectedProperty && selectedProperty.collections && selectedProperty.collections.map((collection, index) => (
        <ListItem key={`${selectedProperty.name}-${collection.name}-${index}`}>
          <ListItemText
            primary={collection.name}
            secondary={`Budget: ${collection.budget}`}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="edit" onClick={() => handleEditItem(collection, 'Collection')}>
              <EditIcon />
            </IconButton>
            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteItem(collection, 'collection')}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );

  const renderDialog = () => (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>
        {dialogType === 'editProperty' ? 'Edit Property' : 'Edit Collection'}
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={dialogType === 'editProperty' ? 'Property Name' : 'Collection Name'}
          type="text"
          fullWidth
          value={dialogType === 'editProperty' ? newPropertyName : newCollectionName}
          onChange={(e) => dialogType === 'editProperty' ? setNewPropertyName(e.target.value) : setNewCollectionName(e.target.value)}
        />
        {dialogType === 'editProperty' && (
          <>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="edit-property-image"
              type="file"
              onChange={handleImageUpload}
            />
            <label htmlFor="edit-property-image">
              <Button variant="contained" component="span" startIcon={<ImageIcon />} style={{ marginTop: '10px' }}>
                Change Image
              </Button>
            </label>
            {propertyImage && <Avatar src={propertyImage} style={{ marginTop: '10px' }} />}
            <TextField
              margin="dense"
              label="iCal Link"
              type="text"
              fullWidth
              value={icalLink}
              onChange={(e) => setIcalLink(e.target.value)}
            />
          </>
        )}
        {dialogType === 'editCollection' && (
          <TextField
            margin="dense"
            label="Budget"
            type="number"
            fullWidth
            value={budget}
            onChange={(e) => setBudget(e.target.value)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        <Button onClick={handleSaveEdit} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderEventContent = (eventInfo) => {
    if (eventInfo.event.display === 'background') {
      return <div className="fc-event-main" style={{ backgroundColor: eventInfo.event.backgroundColor }}></div>;
    }
    const isCheckIn = eventInfo.event.extendedProps.isCheckIn;
    const isCheckOut = eventInfo.event.extendedProps.isCheckOut;
    return (
      <div style={{
        width: '100%',
        height: '100%',
        background: `linear-gradient(to ${isCheckIn ? 'right' : 'left'}, ${eventInfo.event.backgroundColor} 50%, transparent 50%)`
      }}>
        {eventInfo.event.title}
      </div>
    );
  };

  const renderCalendar = () => (
    <Box sx={{ mt: 2, height: isMobile ? '400px' : '600px' }}>
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, listPlugin]}
        initialView={isMobile ? 'listMonth' : 'dayGridMonth'}
        events={calendarEvents}
        eventOrder="background,start,-duration,allDay,title"
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: isMobile ? 'listMonth' : 'dayGridMonth,timeGridWeek,listMonth'
        }}
        height="100%"
        eventClick={(info) => {
          const { extendedProps } = info.event;
          const message = `Booking Details:
Start: ${extendedProps.bookingStart.toDateString()}
End: ${extendedProps.bookingEnd.toDateString()}
${extendedProps.description ? `Description: ${extendedProps.description}` : ''}
${extendedProps.phoneLastFourDigits ? `Phone: ${extendedProps.phoneLastFourDigits}` : ''}
${extendedProps.reservationUrl ? `Reservation URL: ${extendedProps.reservationUrl}` : ''}`;

          if (extendedProps.reservationUrl) {
            if (window.confirm(message + '\n\nClick OK to open the reservation URL.')) {
              window.open(extendedProps.reservationUrl, '_blank');
            }
          } else {
            alert(message);
          }
        }}
        eventContent={renderEventContent}
      />
    </Box>
  );

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Property Management
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <Typography variant="h6" gutterBottom>
              Properties
            </Typography>
            <Box sx={{ display: 'flex', marginBottom: '20px' }}>
              <TextField
                label="New Property"
                variant="outlined"
                value={newPropertyName}
                onChange={(e) => setNewPropertyName(e.target.value)}
                style={{ flexGrow: 1, marginRight: '10px' }}
              />
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleAddProperty}
              >
              Add Property
              </Button>
            </Box>
            <Box sx={{ marginBottom: '20px' }}>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleImageUpload}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span" startIcon={<ImageIcon />}>
                  Upload Image
                </Button>
              </label>
              {propertyImage && <Avatar src={propertyImage} style={{ marginTop: '10px' }} />}
            </Box>
            <TextField
              label="iCal Link"
              variant="outlined"
              fullWidth
              value={icalLink}
              onChange={(e) => setIcalLink(e.target.value)}
              style={{ marginBottom: '20px' }}
            />
            {renderPropertyList()}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>
              Collections for {selectedProperty ? selectedProperty.name : 'No Property Selected'}
            </Typography>
            {selectedProperty && (
              <>
                <Box sx={{ display: 'flex', marginBottom: '20px' }}>
                  <TextField
                    label="New Collection"
                    variant="outlined"
                    value={newCollectionName}
                    onChange={(e) => setNewCollectionName(e.target.value)}
                    style={{ flexGrow: 1, marginRight: '10px' }}
                  />
                  <TextField
                    label="Budget"
                    variant="outlined"
                    type="number"
                    value={budget}
                    onChange={(e) => setBudget(e.target.value)}
                    style={{ flexGrow: 1, marginRight: '10px' }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddCollection}
                    disabled={!selectedProperty}
                  >
                    Add Collection
                  </Button>
                </Box>
                {renderCollectionList()}
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      {selectedProperty && selectedProperty.icalLink && (
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
            <Typography variant="h6" gutterBottom>
              Calendar for {selectedProperty.name}
            </Typography>
            {renderCalendar()}
          </Paper>
        </Grid>
      )}
      {renderDialog()}
    </Container>
  );
};

export default PropertyManagementView;



/* 510 */