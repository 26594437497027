import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  FormControlLabel,
  Autocomplete,
  useMediaQuery,
  useTheme,
  Card,
  CardContent,
  CardActions,
  Link,
  Collapse,
  Snackbar,
  Alert,
  Menu,
} from '@mui/material';
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  CheckCircle as CheckCircleIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Phone as PhoneIcon,
  Email as EmailIcon,
  LocationOn as LocationOnIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const severityColors = {
  immediately: '#FF0000',
  beforeNextStay: '#FFA500',
  '30Days': '#FFFF00',
  ongoing: '#00FF00',
  future: '#0000FF',
};

const severityOptions = [
  { value: 'immediately', label: 'Immediately' },
  { value: 'beforeNextStay', label: 'Before Next Stay' },
  { value: '30Days', label: '30 Days' },
  { value: 'ongoing', label: 'Ongoing' },
  { value: 'future', label: 'Future' },
];

const repeatOptions = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: 'yearly', label: 'Yearly' },
];

const MaintenanceView = ({
  maintenanceItems,
  onRemove,
  onUpdate,
  propertyOptions,
  filterMaintenanceItems,
  bulkDelete,
  bulkEdit,
  selectedItems,
  setSelectedItems,
  completeItems,
  onAddMaintenanceItem,
  providers,
  onAddProvider,
  onUpdateProvider,
  onRemoveProvider,
  properties,
  calendarEvents,
}) => {
  const [filteredItems, setFilteredItems] = useState(maintenanceItems);
  const [filterProperty, setFilterProperty] = useState('');
  const [filterSeverity, setFilterSeverity] = useState('');
  const [filterStatus, setFilterStatus] = useState('active');
  const [editItem, setEditItem] = useState(null);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [newProvider, setNewProvider] = useState({
    name: '',
    business: '',
    phone: '',
    email: '',
    address: '',
    properties: [],
  });
  const [editProvider, setEditProvider] = useState(null);
  const [isEditProviderDialogOpen, setIsEditProviderDialogOpen] = useState(false);
  const [expandedItem, setExpandedItem] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [newItem, setNewItem] = useState({
    maintenanceItem: '',
    startDate: null,
    dueDate: null,
    repeats: false,
    repeatFrequency: '',
    severity: '',
    assignedTo: '',
    notes: '',
    selectedProperties: [],
  });

  useEffect(() => {
    setFilteredItems(maintenanceItems);
  }, [maintenanceItems]);

  const handleFilterChange = () => {
    filterMaintenanceItems(
      filterProperty ? [filterProperty] : [],
      filterSeverity ? [filterSeverity] : [],
      filterStatus
    );
  };

  const handleSelectAll = (event) => {
    setSelectedItems(event.target.checked ? filteredItems : []);
  };

  const handleSelectItem = (item) => {
    const selectedIndex = selectedItems.indexOf(item);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = [...selectedItems, item];
    } else if (selectedIndex === 0) {
      newSelected = selectedItems.slice(1);
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = selectedItems.slice(0, -1);
    } else if (selectedIndex > 0) {
      newSelected = [
        ...selectedItems.slice(0, selectedIndex),
        ...selectedItems.slice(selectedIndex + 1),
      ];
    }

    setSelectedItems(newSelected);
  };

  const handleEdit = (item) => {
    setEditItem(item);
    setIsEditDialogOpen(true);
  };

  const handleEditSave = async () => {
    try {
      await onUpdate(editItem.id, editItem);
      setIsEditDialogOpen(false);
      setEditItem(null);
      
      if (editItem.assignedTo !== maintenanceItems.find(item => item.id === editItem.id).assignedTo) {
        await sendNotification(editItem);
        setSnackbarMessage('Task updated and notification sent');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } else {
        setSnackbarMessage('Task updated successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Failed to update maintenance item:', error);
      setSnackbarMessage('Failed to update maintenance item');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditCancel = () => {
    setIsEditDialogOpen(false);
    setEditItem(null);
  };

  const handleComplete = () => {
    completeItems(selectedItems);
    setSelectedItems([]);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    handleMenuClose();
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAddMaintenanceItemSubmit = async (e) => {
    e.preventDefault();
    if (newItem.selectedProperties.length === 0) {
      setSnackbarMessage('Please select at least one property');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    setIsSubmitting(true);
    const newMaintenanceItem = {
      ...newItem,
      frequency: newItem.repeats ? 'recurring' : 'once',
      frequencyDays: newItem.repeats ? getFrequencyDays(newItem.repeatFrequency) : null,
      properties: newItem.selectedProperties,
    };
    try {
      const addedItem = await onAddMaintenanceItem(newMaintenanceItem);
      if (addedItem.assignedTo) {
        await sendNotification(addedItem);
        setSnackbarMessage('Maintenance item added and notification sent');
      } else {
        setSnackbarMessage('Maintenance item added successfully');
      }
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      resetForm();
    } catch (error) {
      console.error('Failed to add maintenance item:', error);
      setSnackbarMessage('Failed to add maintenance item');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getFrequencyDays = (frequency) => {
    const frequencyMap = { weekly: 7, monthly: 30, quarterly: 90, yearly: 365 };
    return frequencyMap[frequency] || 0;
  };

  const resetForm = () => {
    setNewItem({
      maintenanceItem: '',
      startDate: null,
      dueDate: null,
      repeats: false,
      repeatFrequency: '',
      severity: '',
      assignedTo: '',
      notes: '',
      selectedProperties: [],
    });
  };

  const handleAddProviderSubmit = (e) => {
    e.preventDefault();
    onAddProvider(newProvider);
    setNewProvider({
      name: '',
      business: '',
      phone: '',
      email: '',
      address: '',
      properties: [],
    });
    setSnackbarMessage('Provider added successfully');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const handleEditProvider = (provider) => {
    setEditProvider(provider);
    setIsEditProviderDialogOpen(true);
  };

  const handleEditProviderSave = () => {
    onUpdateProvider(editProvider.id, editProvider);
    setIsEditProviderDialogOpen(false);
    setEditProvider(null);
    setSnackbarMessage('Provider updated successfully');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  };

  const handleEditProviderCancel = () => {
    setIsEditProviderDialogOpen(false);
    setEditProvider(null);
  };

  const sendNotification = async (item) => {
    const assigneeEmail = getAssigneeEmail(item.assignedTo);
    if (!assigneeEmail) {
      console.error('Cannot send notification: Assignee email not found');
      return;
    }

    try {
      const response = await fetch('/api/notify-task-assignment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          taskId: item.id,
          assignedTo: item.assignedTo,
          taskName: item.maintenanceItem,
          dueDate: item.dueDate,
          assigneeEmail: assigneeEmail,
          severity: item.severity,
          property: item.properties.map(p => p.name).join(', '),
          notes: item.notes
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log('Notification sent successfully');
    } catch (error) {
      console.error('Error sending notification:', error);
      throw error;
    }
  };

  const getAssigneeEmail = (assignedTo) => {
    const provider = providers.find(p => p.name === assignedTo);
    if (!provider || !provider.email) {
      console.error(`No email found for provider: ${assignedTo}`);
      return null;
    }
    return provider.email;
  };

  const MobileMaintenanceCard = ({ item }) => {
    const [expanded, setExpanded] = useState(false);

    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6">{item.maintenanceItem}</Typography>
          <Typography variant="body2" color="text.secondary">
            Due: {dayjs(item.dueDate).format('MM/DD/YYYY')}
          </Typography>
          <Chip
            label={item.severity}
            style={{
              backgroundColor: severityColors[item.severity],
              color: ['immediately', 'beforeNextStay'].includes(item.severity) ? 'white' : 'black',
            }}
          />
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => setExpanded(!expanded)}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            {expanded ? 'Less' : 'More'}
          </Button>
          <IconButton size="small" onClick={() => handleEdit(item)}>
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={() => onRemove(item.id)}>
            <DeleteIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography variant="body2">Assigned To: {item.assignedTo}</Typography>
            <Typography variant="body2">Properties: {item.properties.map(p => p.name).join(', ')}</Typography>
            <Typography variant="body2">Notes: {item.notes}</Typography>
            <Typography variant="body2">Status: {item.status}</Typography>
            {renderProviderInfo(item.assignedTo)}
          </CardContent>
        </Collapse>
      </Card>
    );
  };

  const MobileProviderCard = ({ provider }) => {
    return (
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant="h6">{provider.name}</Typography>
          <Typography variant="body2">{provider.business}</Typography>
          <Box sx={{ mt: 1 }}>
            <Link href={`tel:${provider.phone}`} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
              <PhoneIcon sx={{ mr: 1 }} fontSize="small" />
              {provider.phone}
            </Link>
            <Link href={`mailto:${provider.email}`} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
              <EmailIcon sx={{ mr: 1 }} fontSize="small" />
              {provider.email}
            </Link>
            <Link href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(provider.address)}`} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center' }}>
              <LocationOnIcon sx={{ mr: 1 }} fontSize="small" />
              {provider.address}
            </Link>
          </Box>
          <Box sx={{ mt: 1 }}>
            {provider.properties.map((property) => (
              <Chip key={property.id} label={property.name} sx={{ mr: 0.5, mb: 0.5 }} />
            ))}
          </Box>
        </CardContent>
        <CardActions>
          <IconButton size="small" onClick={() => handleEditProvider(provider)}>
            <EditIcon />
          </IconButton>
          <IconButton size="small" onClick={() => onRemoveProvider(provider.id)}>
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Card>
    );
  };

  const renderFilterControls = () => (
    <Box sx={{
      display: 'flex', flexDirection: isMobile ? 'column' : 'row', gap: 2}}>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel>Property</InputLabel>
        <Select
          value={filterProperty}
          onChange={(e) => setFilterProperty(e.target.value)}
          label="Property"
        >
          <MenuItem value="">All</MenuItem>
          {propertyOptions.map((property) => (
            <MenuItem key={property.id} value={property.id}>
              {property.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel>Severity</InputLabel>
        <Select
          value={filterSeverity}
          onChange={(e) => setFilterSeverity(e.target.value)}
          label="Severity"
        >
          <MenuItem value="">All</MenuItem>
          {severityOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ minWidth: 120 }}>
        <InputLabel>Status</InputLabel>
        <Select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          label="Status"
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="completed">Completed</MenuItem>
          <MenuItem value="all">All</MenuItem>
        </Select>
      </FormControl>
      <Button
        variant="contained"
        startIcon={<FilterListIcon />}
        onClick={handleFilterChange}
        size="small"
      >
        Filter
      </Button>
    </Box>
  );

  const renderBulkActions = () => (
    <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => bulkDelete(selectedItems)}
        disabled={selectedItems.length === 0}
        size="small">
        Delete Selected
      </Button>
      <Button
        variant="contained"
        color="primary"
        onClick={() => bulkEdit(selectedItems)}
        disabled={selectedItems.length === 0}
        size="small"
      >
        Edit Selected
      </Button>
      <Button
        variant="contained"
        color="success"
        startIcon={<CheckCircleIcon />}
        onClick={handleComplete}
        disabled={selectedItems.length === 0}
        size="small"
      >
        Complete Selected
      </Button>
    </Box>
  );

  const renderProviderInfo = (providerName) => {
    const provider = providers.find(p => p.name === providerName);
    if (!provider) return null;

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1">Provider Information:</Typography>
        <Link href={`tel:${provider.phone}`} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          <PhoneIcon sx={{ mr: 1 }} fontSize="small" />
          {provider.phone}
        </Link>
        <Link href={`mailto:${provider.email}`} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          <EmailIcon sx={{ mr: 1 }} fontSize="small" />
          {provider.email}
        </Link>
        <Link href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(provider.address)}`} target="_blank" rel="noopener noreferrer" sx={{ display: 'flex', alignItems: 'center' }}>
          <LocationOnIcon sx={{ mr: 1 }} fontSize="small" />
          {provider.address}
        </Link>
      </Box>
    );
  };

  const renderMaintenanceTable = () => (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="maintenance items table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={selectedItems.length > 0 && selectedItems.length < filteredItems.length}
                checked={filteredItems.length > 0 && selectedItems.length === filteredItems.length}
                onChange={handleSelectAll}
              />
            </TableCell>
            <TableCell>Maintenance Item</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Severity</TableCell>
            <TableCell>Assigned To</TableCell>
            <TableCell>Properties</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredItems.map((item) => (
            <React.Fragment key={item.id}>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                onClick={() => setExpandedItem(expandedItem === item.id ? null : item.id)}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedItems.indexOf(item) !== -1}
                    onChange={(event) => {
                      event.stopPropagation();
                      handleSelectItem(item);
                    }}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  {item.maintenanceItem}
                </TableCell>
                <TableCell>
                  {item.startDate ? dayjs(item.startDate).format('MM/DD/YYYY') : 'N/A'}
                </TableCell>
                <TableCell>
                  {item.dueDate ? dayjs(item.dueDate).format('MM/DD/YYYY') : 'N/A'}
                </TableCell>
                <TableCell>
                  <Chip
                    label={item.severity}
                    style={{
                      backgroundColor: severityColors[item.severity],
                      color: ['immediately', 'beforeNextStay'].includes(item.severity) ? 'white' : 'black',
                    }}
                  />
                </TableCell>
                <TableCell>{item.assignedTo}</TableCell>
                <TableCell>
                  {item.properties.map((property) => (
                    <Chip key={property.id} label={property.name} sx={{ mr: 0.5, mb: 0.5 }} />
                  ))}
                </TableCell>
                <TableCell>{item.status}</TableCell>
                <TableCell>
                  <IconButton onClick={(event) => {
                    event.stopPropagation();
                    handleEdit(item);
                  }} size="small">
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={(event) => {
                    event.stopPropagation();
                    onRemove(item.id);
                  }} size="small">
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                  <Collapse in={expandedItem === item.id} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Typography variant="h6" gutterBottom component="div">
                        Details
                      </Typography>
                      <Typography variant="body2">Notes: {item.notes}</Typography>
                      {renderProviderInfo(item.assignedTo)}
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renderAddMaintenanceForm = () => (
    <Box component="form" onSubmit={handleAddMaintenanceItemSubmit} sx={{ maxWidth: 600, margin: 'auto', mt: 4 }}>
      <Typography variant="h5" gutterBottom>
        Add Maintenance Item
      </Typography>

      <TextField
        label="Maintenance Item"
        value={newItem.maintenanceItem}
        onChange={(e) => setNewItem({ ...newItem, maintenanceItem: e.target.value })}
        required
        fullWidth
        margin="normal"
      />

      <DatePicker
        label="Start Date"
        value={newItem.startDate}
        onChange={(newValue) => setNewItem({ ...newItem, startDate: newValue })}
        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
        renderDay={(day, _value, DayComponentProps) => {
        const bookingStatus = getBookingStatus(day);
        const color = getDateColor(bookingStatus);
        
        return (
          <Box
            sx={{
              backgroundColor: color,
              borderRadius: '50%',
              width: 36,
              height: 36,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: color === 'yellow' ? 'black' : 'white',
            }}
          >
            {DayComponentProps.children}
          </Box>
        );
      }}
      />

      <DatePicker
        label="Due Date"
        value={newItem.dueDate}
        onChange={(newValue) => setNewItem({ ...newItem, dueDate: newValue })}
        renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
        renderDay={(day, _value, DayComponentProps) => {
          const isSelected =
            !DayComponentProps.outsideCurrentMonth &&
            calendarEvents.some(
              (event) =>
                dayjs(day).isSame(event.start, 'day') ||
                dayjs(day).isSame(event.end, 'day') ||
                (dayjs(day).isAfter(event.start) && dayjs(day).isBefore(event.end))
            );

          return (
            <Box
              sx={{
                ...(!isSelected && {
                  backgroundColor: getDateColor(getBookingStatus(day)),
                }),
              }}
            >
              {DayComponentProps.children}
            </Box>
          );
        }}
      />

      <FormControlLabel
        control={
          <Checkbox
            checked={newItem.repeats}
            onChange={(e) => setNewItem({ ...newItem, repeats: e.target.checked })}
          />
        }
        label="Repeats"
      />

      {newItem.repeats && (
        <FormControl fullWidth margin="normal">
          <InputLabel>Repeat Frequency</InputLabel>
          <Select
            value={newItem.repeatFrequency}
            onChange={(e) => setNewItem({ ...newItem, repeatFrequency: e.target.value })}
            required
          >
            {repeatOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      <FormControl fullWidth margin="normal" required>
        <InputLabel>Severity</InputLabel>
        <Select
          value={newItem.severity}
          onChange={(e) => setNewItem({ ...newItem, severity: e.target.value })}
        >
          {severityOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth margin="normal">
        <InputLabel>Assigned To</InputLabel>
        <Select
          value={newItem.assignedTo}
          onChange={(e) => setNewItem({ ...newItem, assignedTo: e.target.value })}
        >
          {providers.map((provider) => (
            <MenuItem key={provider.id} value={provider.name}>
              {provider.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Autocomplete
        multiple
        options={propertyOptions}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Properties"
            margin="normal"
            variant="outlined"
            error={newItem.selectedProperties.length === 0}
            helperText={newItem.selectedProperties.length === 0 ? "Please select at least one property" : ""}
          />
        )}
        value={newItem.selectedProperties}
        onChange={(event, newValue) => setNewItem({ ...newItem, selectedProperties: newValue })}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.id}
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
      />

      <TextField
        label="Notes"
        fullWidth
        margin="normal"
        multiline
        rows={4}
        value={newItem.notes}
        onChange={(e) => setNewItem({ ...newItem, notes: e.target.value })}
      />

      <Box sx={{ mt: 2 }}>
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Add Maintenance Item'}
        </Button>
      </Box>
    </Box>
  );

  const getBookingStatus = (date) => {
    if (!calendarEvents || calendarEvents.length === 0) return 'available';
  
    const bookings = calendarEvents.filter(event => 
      dayjs(date).isBetween(dayjs(event.start), dayjs(event.end), null, '[]')
    );

    if (bookings.length === 0) return 'available';
    if (bookings.some(booking => booking.extendedProps.isCheckIn)) return 'checkIn';
    if (bookings.some(booking => booking.extendedProps.isCheckOut)) return 'checkOut';
    if (bookings.length === 2) return 'checkInOut';
    return 'fullyBooked';
  };

  const getDateColor = (status) => {
    switch (status) {
      case 'available': return 'green';
      case 'checkIn': return 'orange';
      case 'checkOut': return 'yellow';
      case 'checkInOut': return 'orange';
      case 'fullyBooked': return 'red';
      default: return 'inherit';
    }
  };

  const renderEditDialog = () => (
    <Dialog open={isEditDialogOpen} onClose={handleEditCancel}>
      <DialogTitle>Edit Maintenance Item</DialogTitle>
      <DialogContent>
        {editItem && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
            <TextField
              label="Maintenance Item"
              value={editItem.maintenanceItem}
              onChange={(e) => setEditItem({ ...editItem, maintenanceItem: e.target.value })}
              fullWidth
            />
            <DatePicker
              label="Start Date"
              value={editItem.startDate}
              onChange={(newValue) => setEditItem({ ...editItem, startDate: newValue })}
              renderInput={(params) => <TextField {...params} fullWidth />}
              renderDay={(day, _value, DayComponentProps) => {
                const bookingStatus = getBookingStatus(day);
                const color = getDateColor(bookingStatus);
                
                return (
                  <Box
                    sx={{
                      backgroundColor: color,
                      borderRadius: '50%',
                      width: 36,
                      height: 36,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: color === 'yellow' ? 'black' : 'white',
                    }}
                  >
                    {DayComponentProps.children}
                  </Box>
                );
              }}
            />
            <DatePicker
              label="Due Date"
              value={editItem.dueDate}
              onChange={(newValue) => setEditItem({ ...editItem, dueDate: newValue })}
              renderInput={(params) => <TextField {...params} fullWidth />}
              renderDay={(day, _value, DayComponentProps) => {
                const bookingStatus = getBookingStatus(day);
                const color = getDateColor(bookingStatus);
                
                return (
                  <Box
                    sx={{
                      backgroundColor: color,
                      borderRadius: '50%',
                      width: 36,
                      height: 36,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: color === 'yellow' ? 'black' : 'white',
                    }}
                  >
                    {DayComponentProps.children}
                  </Box>
                );
              }}
            />
            <FormControl fullWidth>
              <InputLabel>Severity</InputLabel>
              <Select
                value={editItem.severity}
                onChange={(e) => setEditItem({ ...editItem, severity: e.target.value })}
                label="Severity"
              >
                {severityOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>Assigned To</InputLabel>
              <Select
                value={editItem.assignedTo}
                onChange={(e) => setEditItem({ ...editItem, assignedTo: e.target.value })}
                label="Assigned To"
              >
                {providers.map((provider) => (
                  <MenuItem key={provider.id} value={provider.name}>
                    {provider.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              label="Notes"
              value={editItem.notes}
              onChange={(e) => setEditItem({ ...editItem, notes: e.target.value })}
              fullWidth
              multiline
              rows={4}
            />
            <FormControl fullWidth>
              <InputLabel>Frequency</InputLabel>
              <Select
                value={editItem.frequency}
                onChange={(e) => setEditItem({ ...editItem, frequency: e.target.value })}
                label="Frequency"
              >
                <MenuItem value="once">Once</MenuItem>
                <MenuItem value="recurring">Recurring</MenuItem>
              </Select>
            </FormControl>
            {editItem.frequency === 'recurring' && (
              <TextField
                label="Frequency (days)"
                type="number"
                value={editItem.frequencyDays}
                onChange={(e) => setEditItem({ ...editItem, frequencyDays: parseInt(e.target.value) })}
                fullWidth
              />
            )}
            <Autocomplete
              multiple
              options={propertyOptions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Properties"
                  variant="outlined"
                  required
                />
              )}
              value={editItem.properties}
              onChange={(event, newValue) => setEditItem({ ...editItem, properties: newValue })}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.id}
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleEditCancel}>Cancel</Button>
        <Button onClick={handleEditSave} variant="contained" color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderProvidersTab = () => (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h5" gutterBottom>
        Add Provider
      </Typography>
      <Box component="form" onSubmit={handleAddProviderSubmit} sx={{ maxWidth: 600, margin: 'auto', mt: 2 }}>
        <TextField
          label="Name"
          value={newProvider.name}
          onChange={(e) => setNewProvider({ ...newProvider, name: e.target.value })}
          required
          fullWidth
          margin="normal"
        />
        <TextField
          label="Business"
          value={newProvider.business}
          onChange={(e) => setNewProvider({ ...newProvider, business: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Phone"
          value={newProvider.phone}
          onChange={(e) => setNewProvider({ ...newProvider, phone: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          value={newProvider.email}
          onChange={(e) => setNewProvider({ ...newProvider, email: e.target.value })}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Address"
          value={newProvider.address}
          onChange={(e) => setNewProvider({ ...newProvider, address: e.target.value })}
          fullWidth
          margin="normal"
        />
        <Autocomplete
          multiple
          options={propertyOptions}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Properties"
              margin="normal"
              variant="outlined"
            />
          )}
          value={newProvider.properties}
          onChange={(event, newValue) => setNewProvider({ ...newProvider, properties: newValue })}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                key={option.id}
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
        />
        <Box sx={{ mt: 2 }}>
          <Button type="submit" variant="contained" color="primary">
            Add Provider
          </Button>
        </Box>
      </Box>

      <Typography variant="h5" sx={{ mt: 4, mb: 2 }}>
        Providers List
      </Typography>
      {isMobile ? (
        providers.map((provider) => (
          <MobileProviderCard key={provider.id} provider={provider} />
        ))
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="providers table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Business</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Properties</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providers.map((provider) => (
                <TableRow key={provider.id}>
                  <TableCell>{provider.name}</TableCell>
                  <TableCell>{provider.business}</TableCell>
                  <TableCell>
                    <Link href={`tel:${provider.phone}`}>{provider.phone}</Link>
                  </TableCell>
                  <TableCell>
                    <Link href={`mailto:${provider.email}`}>{provider.email}</Link>
                  </TableCell>
                  <TableCell>
                    <Link href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(provider.address)}`} target="_blank" rel="noopener noreferrer">
                      {provider.address}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {provider.properties.map((property) => (
                      <Chip key={property.id} label={property.name} sx={{ mr: 0.5, mb: 0.5 }} />
                    ))}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditProvider(provider)} size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onRemoveProvider(provider.id)} size="small">
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );

  const tabLabels = ['Maintenance Items', 'Add Item', 'Providers'];

  const renderTabs = () => {
    if (isMobile) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6">{tabLabels[activeTab]}</Typography>
          <IconButton onClick={handleMenuClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {tabLabels.map((label, index) => (
              <MenuItem key={label} onClick={() => handleTabChange(null, index)}>
                {label}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      );
    }

    return (
      <Tabs value={activeTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
        {tabLabels.map((label) => (
          <Tab key={label} label={label} />
        ))}
      </Tabs>
    );
  };

  return (
    <Box sx={{ width: '100%', overflowX: 'auto' }}>
      {renderTabs()}
      
      {activeTab === 0 && (
        <>
          <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', justifyContent: 'space-between', mb: 2, gap: 2 }}>
            {renderFilterControls()}
            {renderBulkActions()}
          </Box>
          {isMobile ? (
            filteredItems.map((item) => (
              <MobileMaintenanceCard key={item.id} item={item} />
            ))
          ) : (
            renderMaintenanceTable()
          )}
        </>
      )}

      {activeTab === 1 && renderAddMaintenanceForm()}

      {activeTab === 2 && renderProvidersTab()}

      {renderEditDialog()}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MaintenanceView;