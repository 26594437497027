import React, { useState, useEffect, useRef, useMemo } from 'react';
import {
  AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem,
  ListItemIcon, ListItemText, Autocomplete, TextField, useMediaQuery,
  Box, CssBaseline, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  Menu as MenuIcon, AccountCircle as AccountCircleIcon, Dashboard as DashboardIcon,
  ShoppingCart as ShoppingCartIcon, Add as AddIcon,
  Settings as SettingsIcon, Inventory as InventoryIcon, Home as HomeIcon,
  Build as BuildIcon, Warning as WarningIcon
} from '@mui/icons-material';
import Dash from './Dash';
import AddEntryView from './AddEntryView';
import PurchaseOrdersView from './PurchaseOrdersView';
import InventoryView from './InventoryView';
import PropertyManagementView from './PropertyManagementView';
import MaintenanceView from './MaintenanceView';
import DamagesView from './DamagesView';
import FetchIcalHandler from './FetchIcalHandler';
import { addDays, parseISO } from 'date-fns';
import { normalizeData, denormalizeData } from './normalizeData';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Sidebar from './components/Sidebar';
import LoadingSpinner from './components/LoadingSpinner';
import Toast from './components/Toast';
import CustomAppBar from './components/CustomAppBar';
import FadeIn from './components/FadeIn';
import GridLayout from './components/GridLayout';
import StyledButton from './components/StyledButton';
import Footer from './components/Footer';

const drawerWidth = 240;

// Create theme function
const createAppTheme = (mode) => createTheme({
  palette: {
    mode,
  },
});

const App = () => {
  const [state, setState] = useState(() => {
    const savedState = localStorage.getItem('appState');
    console.log('Loading state from localStorage:', savedState);
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      return denormalizeData(parsedState);
    }
    return {
      entries: [],
      inventory: [],
      properties: [],
      collections: [],
      budgetData: {},
      maintenanceItems: [],
      providers: [],
      damages: [],
    };
  });

  const [filteredEntries, setFilteredEntries] = useState(state.entries);
  const [filteredInventory, setFilteredInventory] = useState(state.inventory);
  const [filteredMaintenanceItems, setFilteredMaintenanceItems] = useState(state.maintenanceItems);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [selectedInventoryItems, setSelectedInventoryItems] = useState([]);
  const [selectedMaintenanceItems, setSelectedMaintenanceItems] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [clearDataDialogOpen, setClearDataDialogOpen] = useState(false);
  const entryRefs = useRef([]);
  const [mode, setMode] = useState('light');

  const theme = useMemo(() => createAppTheme(mode), [mode]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const normalizedState = normalizeData(state);
    try {
      localStorage.setItem('appState', JSON.stringify(normalizedState));
    } catch (e) {
      if (e instanceof DOMException && e.name === 'QuotaExceededError') {
        console.error('localStorage quota exceeded');
        // Implement fallback behavior or notify the user
        alert('Storage limit reached. Please delete some old data.');
      } else {
        throw e; // re-throw if it's not a quota exceeded error
      }
    }
    setFilteredEntries(state.entries);
    setFilteredInventory(state.inventory);
    setFilteredMaintenanceItems(state.maintenanceItems);
    console.log('Current state:', state);
  }, [state]);

  useEffect(() => {
    cleanUpBudgetData();
  }, []);

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const response = await fetch('https://api.stayfu.com/api/upload-image', {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const { imagePath } = await response.json();
      return imagePath;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const updateState = (key, updateFn) => {
    setState(prevState => {
      const updatedState = {
        ...prevState,
        [key]: updateFn(prevState[key])
      };
      const normalizedState = normalizeData(updatedState);
      try {
        localStorage.setItem('appState', JSON.stringify(normalizedState));
      } catch (e) {
        if (e instanceof DOMException && e.name === 'QuotaExceededError') {
          console.error('localStorage quota exceeded');
          // Implement fallback behavior or notify the user
          alert('Storage limit reached. Please delete some old data.');
        } else {
          throw e; // re-throw if it's not a quota exceeded error
        }
      }
      console.log('Saving state to localStorage:', normalizedState);
      return updatedState;
    });
  };

  const addEntry = (entry) => {
    const newEntry = {
      ...entry,
      id: Date.now().toString(),
      spentAmount: 0,
      ordered: false,
      quantity: entry.quantity || 1,
    };
    console.log('Adding new entry:', newEntry);
    updateState('entries', prevEntries => [...prevEntries, newEntry]);
  };

  const removeEntry = (entry) => {
    console.log('Removing entry:', entry);
    updateState('entries', prevEntries => prevEntries.filter(e => e.id !== entry.id));
    if (entry.ordered) {
      updateBudgetData(entry.collectionName, entry.pricePaid, 'remove', entry.quantity);
    }
  };

  const removeSelectedEntries = () => {
    console.log('Removing selected entries:', selectedEntries);
    selectedEntries.forEach(entry => {
      if (entry.ordered) {
        updateBudgetData(entry.collectionName, entry.pricePaid, 'remove', entry.quantity);
      }
    });
    updateState('entries', prevEntries => prevEntries.filter(entry => !selectedEntries.includes(entry)));
    setSelectedEntries([]);
  };

  const updateEntry = (updatedEntry) => {
    console.log('Updating entry:', updatedEntry);
    updateState('entries', prevEntries => {
      const index = prevEntries.findIndex(entry => entry.id === updatedEntry.id);
      prevEntries.map(entry => entry.id === updatedEntry.id ? updatedEntry : entry)
      if (index === -1) {
        console.error(`Entry with id ${updatedEntry.id} not found`);
        return prevEntries;
      }

      const oldEntry = prevEntries[index];
      const newEntries = [...prevEntries];
      newEntries[index] = {
        ...updatedEntry,
        quantity: updatedEntry.quantity || 1,
      };

      if (oldEntry.ordered && !updatedEntry.ordered) {
        updateBudgetData(oldEntry.collectionName, oldEntry.pricePaid, 'remove', oldEntry.quantity);
      } else if (!oldEntry.ordered && updatedEntry.ordered) {
        updateBudgetData(updatedEntry.collectionName, updatedEntry.pricePaid, 'add', updatedEntry.quantity);
      } else if (oldEntry.ordered && updatedEntry.ordered &&
                 (oldEntry.collectionName !== updatedEntry.collectionName || 
                  oldEntry.pricePaid !== updatedEntry.pricePaid ||
                  oldEntry.quantity !== updatedEntry.quantity)) {
        updateBudgetData(oldEntry.collectionName, oldEntry.pricePaid, 'remove', oldEntry.quantity);
        updateBudgetData(updatedEntry.collectionName, updatedEntry.pricePaid, 'add', updatedEntry.quantity);
      }

      return newEntries;
    });
  };

  const updateBudgetData = (collectionName, amount, action, quantity = 1) => {
    console.log('Updating budget data:', { collectionName, amount, action, quantity });
    if (!collectionName) {
      console.error('Attempted to update budget data with undefined collection name');
      return;
    }
    updateState('budgetData', prevBudgetData => {
      const updatedBudgetData = { ...prevBudgetData };
      if (!updatedBudgetData[collectionName]) {
        updatedBudgetData[collectionName] = { spent: 0, ordered: 0 };
      }
      const totalAmount = parseFloat(amount) * parseInt(quantity);
      if (action === 'add') {
        updatedBudgetData[collectionName].ordered += totalAmount;
      } else if (action === 'remove') {
        updatedBudgetData[collectionName].ordered = Math.max(updatedBudgetData[collectionName].ordered - totalAmount, 0);
      } else if (action === 'spend') {
        updatedBudgetData[collectionName].spent += totalAmount;
        updatedBudgetData[collectionName].ordered = Math.max(updatedBudgetData[collectionName].ordered - totalAmount, 0);
      }
      console.log('Updated budget data:', updatedBudgetData);
      return updatedBudgetData;
    });
  };

  const cleanUpBudgetData = () => {
    updateState('budgetData', prevBudgetData => {
      const cleanedBudgetData = {};
      Object.keys(prevBudgetData).forEach(key => {
        if (key !== 'undefined' && key !== 'null') {
          cleanedBudgetData[key] = prevBudgetData[key];
        }
      });
      return cleanedBudgetData;
    });
  };

  const sortEntries = (column, order) => {
    const sortedEntries = [...filteredEntries].sort((a, b) => {
      if (a[column] < b[column]) return order === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return order === 'asc' ? 1 : -1;
      return 0;
    });
    setFilteredEntries(sortedEntries);
  };

  const filterItems = (items, properties, collections) => {
    if (properties.length === 0 && collections.length === 0) return items;
    return items.filter(item => 
      (properties.length === 0 || (item.propertyName && properties.includes(item.propertyName))) &&
      (collections.length === 0 || (item.collectionName && collections.includes(item.collectionName)))
    );
  };

  const filterEntries = (properties, collections) => {
    setFilteredEntries(filterItems(state.entries, properties, collections));
  };

  const filterInventory = (properties, collections) => {
    setFilteredInventory(filterItems(state.inventory, properties, collections));
  };

  const extractASIN = (url) => {
    const asinMatch = url.match(/(?:dp|gp\/product|ASIN)\/([A-Z0-9]{10})/);
    return asinMatch ? asinMatch[1] : null;
  };

  const openAmazonCart = (items) => {
    const amazonBaseURL = 'https://www.amazon.com/gp/aws/cart/add.html?AssociateTag=quickshop0760-20';
    const cartItems = items
      .map((item, index) => {
        const asin = extractASIN(item.url);
        return asin ? `&ASIN.${index + 1}=${asin}&Quantity.${index + 1}=${item.quantity}` : '';
      })
      .join('');
    window.open(amazonBaseURL + cartItems, '_blank');
  };

  const handleAddAllToCart = () => {
    openAmazonCart(filteredEntries);
    markAsOrdered(filteredEntries);
  };

  const handleAddSelectedToCart = () => {
    openAmazonCart(selectedEntries);
    markAsOrdered(selectedEntries);
  };
  
  const markAsOrdered = (entriesToMark) => {
    console.log('Marking as ordered:', entriesToMark);
    updateState('entries', prevEntries =>
      prevEntries.map(entry => {
        if (entriesToMark.includes(entry) && !entry.ordered) {
          updateBudgetData(entry.collectionName, entry.pricePaid, 'add', entry.quantity);
          return { ...entry, ordered: true };
        }
        return entry;
      })
    );
  };

  const markAsDelivered = (selectedEntries) => {
    console.log('Marking as delivered:', selectedEntries);
    const today = new Date().toISOString().split('T')[0];
    const deliveredEntries = selectedEntries.map(entry => ({
      ...entry,
      delivered: true,
      lastDeliveryDate: today,
      reorderFrequency: entry.reorderFrequency || 365,
      numberInStock: parseInt(entry.quantity) || 1,
      asin: extractASIN(entry.url) || '',
    }));
    console.log('Delivered entries prepared:', deliveredEntries);
  
    moveToInventory(deliveredEntries);
  };

  const moveToInventory = (deliveredEntries) => {
    console.log('Moving to inventory:', deliveredEntries);
    const today = new Date().toISOString().split('T')[0];
    updateState('inventory', prevInventory => {
      const updatedInventory = [...prevInventory];
      deliveredEntries.forEach(entry => {
        const existingItemIndex = updatedInventory.findIndex(item => 
          item.title === entry.title && 
          item.propertyName === entry.propertyName && 
          item.collectionName === entry.collectionName
        );
        
        const quantity = parseInt(entry.quantity) || 1;
        
        if (existingItemIndex !== -1) {
          const existingItem = updatedInventory[existingItemIndex];
          console.log(`Updating existing inventory item: ${existingItem.title}, Old quantity: ${existingItem.numberInStock}, New quantity: ${existingItem.numberInStock + quantity}`);
          existingItem.numberInStock += quantity;
          existingItem.lastDeliveryDate = today;
          existingItem.reorderFrequency = entry.reorderFrequency || existingItem.reorderFrequency || 365;
          existingItem.asin = extractASIN(entry.url) || existingItem.asin || '';
          existingItem.spentAmount += (parseFloat(entry.pricePaid) * quantity);
        } else {
          console.log(`Adding new inventory item: ${entry.title}, Quantity: ${quantity}`);
          updatedInventory.push({
            ...entry,
            id: Date.now().toString() + Math.random().toString(36).substr(2, 9),
            lastDeliveryDate: today,
            reorderFrequency: entry.reorderFrequency || 365,
            numberInStock: quantity,
            asin: extractASIN(entry.url) || '',
            spentAmount: parseFloat(entry.pricePaid) * quantity,
          });
        }
        
        updateBudgetData(entry.collectionName, entry.pricePaid, 'spend', quantity);
      });
      
      console.log('Final inventory after update:', updatedInventory);
      return updatedInventory;
    });

    // Remove delivered entries from the entries list
    updateState('entries', prevEntries => prevEntries.filter(entry => 
      !deliveredEntries.some(deliveredEntry => 
        deliveredEntry.title === entry.title && 
        deliveredEntry.propertyName === entry.propertyName && 
        deliveredEntry.collectionName === entry.collectionName
      )
    ));
  };

  const removeFromInventory = (itemToRemove) => {
    updateState('inventory', prevInventory => prevInventory.filter(item => item !== itemToRemove));
  };

  const removeSelectedFromInventory = () => {
    updateState('inventory', prevInventory =>
      prevInventory.filter(item => !selectedInventoryItems.includes(item))
    );
    setSelectedInventoryItems([]);
  };

  const sendToList = (selectedItems) => {
    updateState('entries', prevEntries => [
      ...prevEntries,
      ...selectedItems.map(item => ({
        ...item,
        id: Date.now().toString(), pricePaid: item.spentAmount / item.numberInStock,
        quantity: item.numberInStock,
        delivered: false,
        ordered: false,
      }))
    ]);
    removeSelectedFromInventory(selectedItems);
  };

  const orderSelected = (selectedItems) => {
    openAmazonCart(selectedItems);

    const updatedItems = selectedItems.map(item => ({ 
      ...item, 
      ordered: true, 
      delivered: false,
      pricePaid: item.spentAmount / item.numberInStock,
      quantity: item.numberInStock,
    }));
    updateState('entries', prevEntries => [...prevEntries, ...updatedItems]);
    updateState('inventory', prevInventory => prevInventory.filter(item => !selectedItems.includes(item)));
    setSelectedInventoryItems([]);
  };

  const markInventoryAsOrdered = orderSelected;

  const handleSearchSelect = (event, value) => {
    const index = state.entries.findIndex(entry => entry.title === value);
    if (index !== -1) {
      const entryElement = entryRefs.current[index];
      if (entryElement) {
        entryElement.scrollIntoView({ behavior: 'smooth' });
        entryElement.style.backgroundColor = 'yellow';
        setTimeout(() => { entryElement.style.backgroundColor = ''; }, 2000);
      }
    }
  };

  const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

  const updateProperties = (newProperties) => {
    console.log('Updating properties:', newProperties);
    updateState('properties', prevProperties => {
      if (newProperties.length < prevProperties.length) {
        return newProperties;
      } else {
        return newProperties.map(newProp => {
          const existingProp = prevProperties.find(p => p.name === newProp.name);
          return existingProp ? { ...existingProp, ...newProp } : newProp;
        });
      }
    });
  };

  const updatePropertyCalendarData = (propertyName, calendarData) => {
  updateState('properties', prevProperties =>
    prevProperties.map(property =>
      property.name === propertyName
        ? { ...property, calendarData }
        : property
    )
  );
};

  const updateCollections = (newCollections) => {
    console.log('Updating collections:', newCollections);
    updateState('collections', prevCollections => {
      const updatedCollections = [...prevCollections];
      newCollections.forEach(newColl => {
        const index = updatedCollections.findIndex(c => c.name === newColl.name);
        if (index !== -1) {
          updatedCollections[index] = {...updatedCollections[index], ...newColl};
        } else {
          updatedCollections.push(newColl);
          // Initialize budget data for the new collection
          updateState('budgetData', prevBudgetData => ({
            ...prevBudgetData,
            [newColl.name]: { spent: 0, ordered: 0 }
          }));
        }
      });
      return updatedCollections;
    });
  };

  const addMaintenanceItem = async (item) => {
    const newItem = {
      ...item,
      id: Date.now().toString(),
      status: 'active',
      dueDate: item.dueDate ? new Date(item.dueDate).toISOString().split('T')[0] : new Date().toISOString().split('T')[0],
      properties: item.properties.map(prop => ({ id: prop.id, name: prop.name })),
    };
    updateState('maintenanceItems', prevItems => [...prevItems, newItem]);

    return newItem;
  };

  const removeMaintenanceItem = (id) => {
    updateState('maintenanceItems', prevItems => prevItems.filter(item => item.id !== id));
  };

  const updateMaintenanceItem = async (id, updatedItem) => {
    updateState('maintenanceItems', prevItems => {
      const oldItem = prevItems.find(item => item.id === id);
      const newItems = prevItems.map(item =>
        item.id === id
          ? {
              ...item,
              ...updatedItem,
              dueDate: updatedItem.dueDate ? new Date(updatedItem.dueDate).toISOString().split('T')[0] : item.dueDate,
              properties: updatedItem.properties.map(prop => ({id: prop.id, name: prop.name }))
            }
          : item
      );

      // Send notification if the assigned person has changed
      if (oldItem && oldItem.assignedTo !== updatedItem.assignedTo) {
        sendNotification(id, updatedItem.assignedTo).catch(error => {
          console.error('Failed to send notification:', error);
        });
      }

      return newItems;
    });
  };

  const filterMaintenanceItems = (properties, severities, status) => {
    setFilteredMaintenanceItems(state.maintenanceItems.filter(item =>
      (properties.length === 0 || item.properties.some(prop => properties.includes(prop.id))) &&
      (severities.length === 0 || severities.includes(item.severity)) &&
      (status === 'all' || item.status === status)
    ));
  };

  const bulkDeleteMaintenanceItems = (itemsToDelete) => {
    updateState('maintenanceItems', prevItems => prevItems.filter(item => !itemsToDelete.includes(item)));
    setSelectedMaintenanceItems([]);
  };

  const bulkEditMaintenanceItems = async (itemsToEdit, updates) => {
    updateState('maintenanceItems', prevItems => {
      const newItems = prevItems.map(item => {
        if (itemsToEdit.includes(item)) {
          const updatedItem = { ...item, ...updates };
          // Send notification if the assigned person has changed
          if (item.assignedTo !== updatedItem.assignedTo) {
            sendNotification(item.id, updatedItem.assignedTo).catch(error => {
              console.error('Failed to send notification:', error);
            });
          }
          return updatedItem;
        }
        return item;
      });
      return newItems;
    });
    setSelectedMaintenanceItems([]);
  };

  const completeMaintenanceItems = (itemsToUpdate) => {
    updateState('maintenanceItems', prevItems => {
      const updatedItems = prevItems.map(item => {
        const updateItem = itemsToUpdate.find(i => i.id === item.id);
        if (updateItem) {
          if (updateItem.frequency === 'recurring') {
            return {
              ...updateItem,
              status: 'active',
              dueDate: addDays(parseISO(updateItem.dueDate), updateItem.frequencyDays).toISOString().split('T')[0]
            };
          } else {
            return { ...updateItem, status: 'completed' };
          }
        }
        return item;
      });
      return updatedItems.filter(item => item.status !== 'completed');
    });
  };

  const addProvider = (provider) => {
    const newProvider = {
      ...provider,
      id: Date.now().toString(),
    };
    updateState('providers', prevProviders => [...prevProviders, newProvider]);
  };

  const updateProvider = (id, updatedProvider) => {
    updateState('providers', prevProviders => prevProviders.map(provider =>
      provider.id === id ? { ...provider, ...updatedProvider } : provider
    ));
  };

  const removeProvider = (id) => {
    updateState('providers', prevProviders => prevProviders.filter(provider => provider.id !== id));
  };

  const getUrgentMaintenanceItems = () => {
    return state.maintenanceItems.filter(item => 
      item.severity === 'immediately' || item.severity === 'beforeNextStay'
    );
  };

  const getUrgentRestockItems = () => {
    const today = new Date();
    return state.inventory.filter(item => {
      const lastDeliveryDate = new Date(item.lastDeliveryDate);
      const daysLeft = item.reorderFrequency - Math.floor((today - lastDeliveryDate) / (1000 * 60 * 60 * 24));
      return daysLeft <= item.reorderFrequency / 3 || item.numberInStock <= (item.reorderThreshold || 0);
    });
  };

  const handleClearAllData = () => {
    setClearDataDialogOpen(true);
  };

  const confirmClearAllData = () => {
    setState({
      entries: [],
      inventory: [],
      properties: [],
      collections: [],
      budgetData: {},
      maintenanceItems: [],
      providers: [],
      damages: [],
    });
    localStorage.removeItem('appState');
    setClearDataDialogOpen(false);
  };

  const sendNotification = async (taskId, assignedTo, providers) => {
    try {
      const provider = providers.find(p => p.name === assignedTo);
      if (!provider || !provider.email) {
        throw new Error(`No email found for provider: ${assignedTo}`);
      }
      const response = await fetch('/api/notify-task-assignment', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ taskId, assignedTo, assigneeEmail: provider.email }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error sending notification:', error);
      throw new Error('Failed to send notification');
    }
  };

  const addDamage = (newDamage) => {
    updateState('damages', prevDamages => [...prevDamages, newDamage]);
  };

  const deleteDamage = (damageId) => {
    updateState('damages', prevDamages => prevDamages.filter(damage => damage.id !== damageId));
  };

  const updateDamage = (updatedDamage) => 
    { updateState('damages', prevDamages => prevDamages.map(damage => damage.id === updatedDamage.id ? updatedDamage : damage ) ); 

    };

    const getUrgentDamageItems = () => {
      return state.damages.filter(item => item.status === 'New');
    };
    

  const menuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, link: '/' },
    { text: 'Add Products', icon: <AddIcon />, link: '/add-entry' },
    { text: 'Purchase Orders', icon: <ShoppingCartIcon />, link: '/purchase-orders' },
    { text: 'Current Inventory', icon: <InventoryIcon />, link: '/inventory' },
    { text: 'Property Management', icon: <HomeIcon />, link: '/property-management' },
    { text: 'Maintenance', icon: <BuildIcon />, link: '/maintenance' },
    { text: 'Damages', icon: <WarningIcon />, link: '/damages' },
    { text: 'Settings', icon: <SettingsIcon />, link: '/settings' },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Router>
        <Box sx={{ display: 'flex' }}>
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar>
              {isSmallScreen && (
                <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} sx={{ mr: 2}}>
                  <MenuIcon />
                </IconButton>
              )}
              <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>StayFu</Typography>
              <Autocomplete
                options={state.entries.map(entry => entry.title)}
                sx={{ width: 300, mr: 2 }}
                renderInput={(params) => (
                  <TextField {...params} label="Search products" variant="outlined" size="small" />
                )}
                onChange={handleSearchSelect}
              />
              <IconButton color="inherit"><AccountCircleIcon /></IconButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant={isSmallScreen ? "temporary" : "permanent"}
            open={isSmallScreen ? drawerOpen : true}
            onClose={isSmallScreen ? handleDrawerToggle : undefined}
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
            }}
          >
            <Toolbar />
            <Box sx={{ overflow: 'auto' }}>
              <List>
                {menuItems.map((item) => (
                  <ListItem button key={item.text} component={Link} to={item.link} onClick={isSmallScreen ? handleDrawerToggle : undefined}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Toolbar />
            
            <Routes>
              <Route exact path="/" element={
                <Dash 
                  urgentMaintenanceItems={getUrgentMaintenanceItems()}
                  urgentRestockItems={getUrgentRestockItems()}
                  urgentDamageItems={getUrgentDamageItems()}
                />
              } />
              <Route path="/add-entry" element={
                <AddEntryView
                  onAddEntry={addEntry}
                  properties={state.properties}
                  collections={state.collections}
                  updateProperties={updateProperties}
                  updateCollections={updateCollections}
                />
              } />
              <Route path="/purchase-orders" element={
                <PurchaseOrdersView
                  entries={filteredEntries}
                  onRemove={removeEntry}
                  onUpdate={updateEntry}
                  updateEntry={updateEntry}
                  onSort={sortEntries}
                  entryRefs={entryRefs}
                  selectedEntries={selectedEntries}
                  setSelectedEntries={setSelectedEntries}
                  handleAddAllToCart={handleAddAllToCart}
                  handleAddSelectedToCart={handleAddSelectedToCart}
                  filterEntries={filterEntries}
                  propertyOptions={state.properties || []}
                  collectionOptions={state.collections}
                  markAsOrdered={markAsOrdered}
                  markAsDelivered={markAsDelivered}
                  bulkDelete={removeSelectedEntries}
                  moveToInventory={moveToInventory}
                  updateProperties={updateProperties}
                  updateCollections={updateCollections}
                  budgetData={state.budgetData}
                />
              } />
              <Route path="/inventory" element={
                <InventoryView
                  inventory={filteredInventory}
                  onRemove={removeFromInventory}
                  onUpdate={(updatedItem) => {
                    updateState('inventory', prevInventory =>
                      prevInventory.map(item => item.id === updatedItem.id ? updatedItem : item)
                    );
                  }}
                  onSendToList={sendToList}
                  onOrderSelected={orderSelected}
                  onSaveInventory={(updatedInventory) => updateState('inventory', () => updatedInventory)}
                  propertyOptions={state.properties}
                  collectionOptions={state.collections}
                  filterInventory={filterInventory}
                  markAsOrdered={markInventoryAsOrdered}
                  bulkDelete={removeSelectedFromInventory}
                  selectedItems={selectedInventoryItems}
                  setSelectedItems={setSelectedInventoryItems}
                />
              } />
              <Route path="/property-management" element={
              <PropertyManagementView
                properties={state.properties}
                collections={state.collections}
                updateProperties={updateProperties}
                updateCollections={updateCollections}
                updatePropertyCalendarData={updatePropertyCalendarData}
              />
            } />

              <Route path="/maintenance" element={
              <MaintenanceView
                maintenanceItems={filteredMaintenanceItems}
                onRemove={removeMaintenanceItem}
                onUpdate={updateMaintenanceItem}
                propertyOptions={state.properties || []}
                filterMaintenanceItems={filterMaintenanceItems}
                bulkDelete={bulkDeleteMaintenanceItems}
                bulkEdit={bulkEditMaintenanceItems}
                selectedItems={selectedMaintenanceItems}
                setSelectedItems={setSelectedMaintenanceItems}
                completeItems={completeMaintenanceItems}
                onAddMaintenanceItem={addMaintenanceItem}
                providers={state.providers}
                onAddProvider={addProvider}
                onUpdateProvider={updateProvider}
                onRemoveProvider={removeProvider}
                calendarEvents={state.calendarEvents} // Add this line
              />
            } />

              <Route path="/damages" element={
              <DamagesView
                damages={state.damages}
                onAddDamage={addDamage}
                onDeleteDamage={deleteDamage}
                onUpdateDamage={updateDamage}
                properties={state.properties}
                providers={state.providers}
              />
            } />

              <Route path="/settings" element={
                <Box>
                  <Typography variant="h4" gutterBottom>Settings</Typography>
                  <Button variant="contained" color="secondary" onClick={handleClearAllData}>
                    Clear All Data
                  </Button>
                </Box>
              } />
              <Route path="/api/fetch-ical" element={<FetchIcalHandler />} />
            </Routes>
          </Box>
        </Box>
      </Router>
      </LocalizationProvider>
      <Dialog
        open={clearDataDialogOpen}
        onClose={() => setClearDataDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Clear All Data?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to clear all data? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setClearDataDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmClearAllData} color="secondary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default App;