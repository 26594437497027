import React, { useState, useEffect } from 'react';
import {
  Paper, Button, Select, MenuItem, FormControl, InputLabel,
  Typography, Toolbar, Chip, useMediaQuery, useTheme,
  Drawer, IconButton, Box, TextField
} from '@mui/material';
import {
  ShoppingCart as ShoppingCartIcon,
  FilterList as FilterListIcon
} from '@mui/icons-material';
import EntryTable from './EntryTable';

const PurchaseOrdersView = ({
  entries,
  onRemove,
  onUpdate,
  onSort,
  entryRefs,
  selectedEntries,
  setSelectedEntries,
  handleAddAllToCart,
  handleAddSelectedToCart,
  filterEntries,
  propertyOptions,
  collectionOptions,
  markAsOrdered,
  markAsDelivered,
  bulkDelete,
  moveToInventory,
  updateProperties,
  updateCollections,
  budgetData,
  updateEntry,
}) => {
  const [filters, setFilters] = useState({
    propertyNames: [],
    collectionNames: [],
  });
  const [filteredEntries, setFilteredEntries] = useState(entries);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedCollection, setSelectedCollection] = useState('');
  const [newCollection, setNewCollection] = useState('');
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [budgetInfo, setBudgetInfo] = useState(null);

  useEffect(() => {
    if (selectedProperty && propertyOptions) {
      const selectedPropertyObj = propertyOptions.find(prop => prop.name === selectedProperty);
      setFilteredCollections(selectedPropertyObj ? selectedPropertyObj.collections : []);
    } else {
      setFilteredCollections([]);
    }
  }, [selectedProperty, propertyOptions]);

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value
    }));
  };

  useEffect(() => {
    if (filters.propertyNames.length === 0 && filters.collectionNames.length === 0) {
      setFilteredEntries(entries);
    } else {
      const newFilteredEntries = entries.filter(entry => 
        (filters.propertyNames.length === 0 || filters.propertyNames.includes(entry.propertyName)) &&
        (filters.collectionNames.length === 0 || filters.collectionNames.includes(entry.collectionName))
      );
      setFilteredEntries(newFilteredEntries);
    }
  
    const newBudgetInfo = calculateBudgetInfo();
    console.log('New Budget Info:', newBudgetInfo);
    setBudgetInfo(newBudgetInfo);
  }, [filters, entries, collectionOptions, budgetData]);

  const clearFilters = () => {
    setFilters({ propertyNames: [], collectionNames: [] });
  };

  const renderFilterControls = () => (
  <>
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>Filter by Property</InputLabel>
      <Select
        multiple
        value={filters.propertyNames}
        onChange={handleFilterChange}
        name="propertyNames"
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} size="small" />
            ))}
          </Box>
        )}
      >
        {propertyOptions && propertyOptions.map((option) => (
          <MenuItem key={option.name} value={option.name}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <FormControl fullWidth sx={{ mb: 2 }}>
      <InputLabel>Filter by Collection</InputLabel>
      <Select
        multiple
        value={filters.collectionNames}
        onChange={handleFilterChange}
        name="collectionNames"
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} size="small" />
            ))}
          </Box>
        )}
      >
        {collectionOptions && collectionOptions
          .filter(option => 
            option && 
            option.name && 
            option.name.trim() !== '' && 
            propertyOptions && propertyOptions.some(prop => prop.collections && prop.collections.some(coll => coll.name === option.name))
          )
          .map((option) => (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
    <Button
      variant="contained"
      color="primary"
      onClick={clearFilters}
      fullWidth
      size="small"
    >
      Clear All Filters
    </Button>
  </>
);

  const handleAddNewCollection = () => {
    if (newCollection && selectedProperty) {
      const updatedCollections = filteredCollections.concat({ name: newCollection, budget: 0 });
      updateCollections(prevCollections => {
        const updatedProps = prevCollections.map(property => {
          if (property.name === selectedProperty) {
            return { ...property, collections: updatedCollections };
          }
          return property;
        });
        return updatedProps;
      });
      setSelectedCollection(newCollection);
      setNewCollection('');
    }
  };

  const handleMarkAsDelivered = () => {
    const uniqueSelectedEntries = selectedEntries.filter((entry, index, self) =>
      index === self.findIndex((t) => 
        t.title === entry.title && 
        t.propertyName === entry.propertyName && 
        t.collectionName === entry.collectionName
      )
    );
    markAsDelivered(uniqueSelectedEntries);
    setSelectedEntries([]);
  };

  const calculateBudgetInfo = () => {
    if (filters.collectionNames.length === 1) {
      const selectedCollectionName = filters.collectionNames[0];
      const selectedCollection = collectionOptions && collectionOptions.find(c => c.name === selectedCollectionName);
      
      if (selectedCollection && budgetData && budgetData[selectedCollectionName]) {
        const totalBudget = selectedCollection.budget || 0;
        const spentAmount = budgetData[selectedCollectionName].spent || 0;
        const orderedAmount = budgetData[selectedCollectionName].ordered || 0;
        const remainingBudget = Math.max(totalBudget - orderedAmount - spentAmount, 0);

        return {
          totalBudget,
          spentAmount,
          orderedAmount,
          remainingBudget
        };
      }
    }
    return null;
  };

  const handleMarkAsOrdered = (entriesToMark) => {
    markAsOrdered(entriesToMark);
    setFilteredEntries(prevEntries => 
      prevEntries.map(entry => 
        entriesToMark.includes(entry) ? { ...entry, ordered: true } : entry
      )
    );
  };

  return (
    <Box sx={{ width: '100%', overflowX: 'hidden' }}>
      <Paper sx={{ p: 1, mb: 2 }}>
        <Toolbar disableGutters sx={{ justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <Typography variant="h6">Purchase Orders</Typography>
          {isMobile && (
            <IconButton onClick={() => setIsFilterDrawerOpen(true)} size="small">
              <FilterListIcon />
            </IconButton>
          )}
        </Toolbar>
        {!isMobile && (
          <Box sx={{ mb: 2 }}>
            {renderFilterControls()}
          </Box>
        )}
        {budgetInfo && (
        <Box sx={{ mt: 2, mb: 2 }}>
          <Typography variant="subtitle1">
            Budget Information for {filters.collectionNames[0]}:
          </Typography>
          <Typography variant="body2">
            Total Budget: ${budgetInfo.totalBudget.toFixed(2)}
          </Typography>
          <Typography variant="body2">
            Ordered Amount: ${budgetInfo.orderedAmount.toFixed(2)}
          </Typography>
          <Typography variant="body2">
            Spent Amount: ${budgetInfo.spentAmount.toFixed(2)}
          </Typography>
          <Typography variant="body2">
            Remaining Budget: ${budgetInfo.remainingBudget.toFixed(2)}
          </Typography>
        </Box>
      )}

        <EntryTable
          entries={filteredEntries}
          onRemove={onRemove}
          onUpdate={updateEntry}
          onSort={onSort}
          entryRefs={entryRefs}
          selectedEntries={selectedEntries}
          setSelectedEntries={setSelectedEntries}
          markAsOrdered={handleMarkAsOrdered}
          markAsDelivered={handleMarkAsDelivered}
          bulkDelete={bulkDelete}
          moveToInventory={moveToInventory}
          properties={propertyOptions}
          collections={collectionOptions}
          updateProperties={updateProperties}
          updateCollections={updateCollections}
          budgetData={budgetData}
        />
        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 1 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleAddAllToCart();
              handleMarkAsOrdered(filteredEntries.filter(entry => !entry.delivered && !entry.ordered));
            }}
            startIcon={<ShoppingCartIcon />}
            size="small"
          >
            Add All to Cart
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleAddSelectedToCart();
              handleMarkAsOrdered(selectedEntries.filter(entry => !entry.delivered && !entry.ordered));
            }}
            startIcon={<ShoppingCartIcon />}
            size="small"
          >
            Add Selected to Cart
          </Button>
        </Box>
      </Paper>
      <Drawer
        anchor="right"
        open={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
      >
        <Box sx={{ width: 250, p: 2 }}>
          <Typography variant="h6" sx={{ mb: 2 }}>Filters</Typography>
          {renderFilterControls()}
        </Box>
      </Drawer>
    </Box>
  );
};

export default PurchaseOrdersView;